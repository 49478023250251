.mc-slider-rotator {
  position: relative;
  margin-bottom: 0px;
  box-sizing: border-box;
  padding-right: 10px;
  padding-bottom: 100px;
  overflow: hidden;

  .slick-slider {
    position: relative;
    display: block;
    width: 1100px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 18px;
    overflow: visible;

    .slick-list {
      // overflow: visible;

      .slick-track {}
    }

    .slick-slide {
      // transform: scale(0.6);
      // opacity: 0;
      -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      transition: all .3s cubic-bezier(.77, 0, .175, 1);

      &.slick-active {
        transform: scale(1);
        opacity: 1;
      }
    }

    .slick-dots {
      width: auto;
      box-sizing: border-box;
      left: 0px;
      right: 0px;
      bottom: -32px;

      li {
        width: 24px;
        height: 2px;
        background-color: $primaryColor;
        border-radius: 50px;
        margin: 0 4px;
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);
        position: relative;

        button {
          width: 100%;
          height: 100%;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          background-color: $primaryColor;
          width: 48px;
          height: 4px;
          top: -1px;
        }
      }
    }

    .slick-arrow {
      top: auto;
      bottom: -32px;
      transform: translate(0px, 0px);
      left: 0px;
      right: 0px;
      margin: auto;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: 100% !important;
      opacity: 1;
      z-index: 3;

      &:before {
        display: none;
      }

      &.slick-prev {
        left: -140px;
        background-image: url('../../../public/assets/images/arrow-L.png');
        bottom: -34px;
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);

        @media (min-width: 992px) {
          &:hover {
            left: -148px;
          }
        }
        

        @media (max-width: 992px) {
          left: -200px;
        }

      }

      &.slick-next {
        right: -140px;
        background-image: url('../../../public/assets/images/arrow-L.png');
        transform: rotate(180deg);
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);

        @media (min-width: 992px) {
          &:hover {
            right: -148px;
          }
        }

        @media (max-width: 992px) {
          right: -200px;
        }
      }
    }
  }

  .mc-slider-rotator__slide {
    position: relative;

    @media only screen and (min-width: 992px) {
      padding: 0px;
      box-sizing: border-box;
    }
  }

  &.size-3 {
    .slick-slider {
      .slick-arrow {
        &.slick-prev {
          left: -144px;
        }

        &.slick-next {
          right: -144px;
        }
      }
    }
  }

  &.davipuntos-awards{
    .mc-slider-rotator .slick-slider .slick-slide{      
    }
  }

}

.single-button {
  cursor: pointer;
  width: 44px;
  height: 44px;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--Primario-Primario-oscuro, #C90C14);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  top: 171px;
  right: -30px;
  z-index: 100;
  &.rotate
{
    .mc-icon {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      -o-transform: rotateY(180deg);
      transition: all .3s;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -ms-transition: all .3s;
      -o-transition: all .3s;
}
  }
  
  @media (max-width: 992px) {
   display: none;
  }
}