.SectionPremioMayor {  
  border-radius: var(--l, 24px);
  background: #FFF;
  margin: 54px 0 88px 0;
  padding: 88px 0 var(--8xl, 88px) 0;
  @media (max-width: 992px) {
    padding: var(--4xl, 56px) var(--m, 16px);
  }
  @media (max-width: 576px) {
    padding: var(--4xl, 56px) var(--m, 0px);
  }

  .img_premioMayor {
    text-align: center;

    img {
      width: 100%;
      max-width: 400px;
    }
  }

  .content {    
    
    max-width: 370px;
    @media (max-width: 992px) {
      max-width: 100%;
     
    }
    h3 {
      font-family: 'Myriad Pro Semibold';
      font-size: 24px;
      margin: 0;
    }
    p{
      margin: 0;
      margin-bottom: 16px;
      strong{
        font-weight: bold;

      }
    }

    @media (max-width: 992px) {
     text-align: center;
    }
    .btn-section{
      display: inline-block;
      @media (max-width: 992px) {
       display: block;
      }
    }

    &.showMobile{
      display: none;
      @media (max-width: 992px) {
        display: block;
       
      }
    }

    &.hide-mobile{
      padding-top: 24px;
      @media (max-width: 992px) {
        h3{
          display: none;
        }
        #parrafo1{
          display: none;
        }
      }
    }
  }

}

.sectionRedemptions{
  border-radius: 24px;
  border: 1px solid #F0F0F0;
  background: linear-gradient(93deg, #FFF -0.23%, #F0F0F0 63.57%);
  height: 280px;
  width: 1100px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 80px;
  position: relative;

  @media only screen and (max-width: 992px) {
    margin-top: 60px;
    height: 350px;
    max-width: 95%;
  }

  @media only screen and (max-width: 992px) {
    .mc-page-content__redemptionsOnAwards--awards {
      height: auto !important;
    }
  }
}