.mc-stair-slider {
  position: relative;
  background-color: #fff;
  padding: 20px 0px;
  padding-top: 120px;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    padding-top: 0px;
    margin-top: 20px;
  }

  &__carousel {
    position: relative;
    z-index: 1;
    height: 550px;
    // overflow: hidden;
    // pointer-events: none;

    @media only screen and (max-width: 992px) {
      height: auto;
    }

    .mc-app-container {
      .slick-slider {
        padding-left: 400px;

        @media only screen and (max-width: 992px) {
          padding-left: 0px;
          padding-bottom: 32px;
        }

        .slick-arrow {
          background-color: transparent;
          bottom: -122px;
          top: auto;
          left: 0px;
          right: 0px;
          margin: auto;
          z-index: 3;
          width: 32px;
          height: 32px;
          &:hover{
            box-shadow: 0px 0px 24px 0px rgba(201, 12, 20, 0.50) !important;
            background-color: transparent !important;
            border-radius: 100px;
            padding: 0;
          }

          @media only screen and (max-width: 992px) {
            bottom: -22px;
          }

          &:before {
            display: none;
          }

          &.slick-prev  {
            left: -226px;
            background-image: url('../../../public/assets/images/slider_left.png');
            background-position: center center;
            background-size: 100%;
            @media only screen and (max-width: 992px) {
              left: -60px;
              right: 0px;
              margin: auto;
            }

            &.slick-disabled {
              opacity: 0.5;
              cursor: no-drop;
            }
          }
          &.slick-next {
            right: 115px;
            background-image: url('../../../public/assets/images/slider_left.png');
            background-position: center center;
            background-size: 100%;
            transform: rotate(180deg) translate(0px, 15px);
            @media only screen and (max-width: 992px) {
              right: -28px;
              left: 0px;
              margin: auto;
            }
          }
        }
        .slick-list {
          overflow: visible;
          .slick-track {
            .slick-slide {
              position: relative;
              display: block !important;
              -webkit-transition: all 500ms ease;
              -moz-transition: all 500ms ease;
              -o-transition: all 500ms ease;
              transition: all 500ms ease;

              div {
                .mc-stair-slider__carousel--item {
                  width: 220px;
                  height: 320px;

                  &__container {
                    background-color: rgb(175, 175, 175);
                    height: 100%;
                    box-sizing: border-box;
                    margin-right: 50px;
                    position: relative;
                    background-repeat: no-repeat;
                    background-size: 100%;

                    @media only screen and (max-width: 992px) {
                      margin-right: 50px;
                    }

                    .overlay-card {
                      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.84) 100%);
                      position: absolute;
                      left: 0px;
                      bottom: 0px;
                      right: 0px;
                      height: 70%;
                      z-index: 2;

                      text-align: center;
                      padding: 0 20px;
                      box-sizing: border-box;

                      h4 {
                        color: #FFF;
                        text-align: center;
                        font-family: "Myriad Pro";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 23.333px;
                        margin-bottom: 0px;
                      }
                      p {
                        color: #FFF;
                        text-align: center;
                        font-family: "Myriad Pro";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18.333px; /* 130.952% */
                      }
                    }

                    &::before {
                      width: 0;
                      height: 0;
                      border-top: 60px solid #fff;
                      border-right: 60px solid transparent;
                      z-index: 2;
                      position: absolute;
                      left: 0px;
                      top: 0px;
                      content: '';
                    }
                    &::after {
                      width: 0;
                      height: 0;
                      border-bottom: 62px solid #fff;
                      border-left: 62px solid transparent;
                      z-index: 2;
                      position: absolute;
                      right: 0px;
                      bottom: 0px;
                      content: '';
                    }

                    svg {
                      position: absolute;
                      right: 0px;
                      bottom: 0px;
                      z-index: 3;
                    }
                  }
                }
              }

              &.slick-active {
                opacity: 1; 
              }

              &.slick-current {
                top: 0px;
              }
            }
          }
        }
      }
    }
    
    &--labels {
      position: absolute;
      z-index: 2;
      width: 360px;
      height: 432px;

      @media only screen and (max-width: 992px) {
        position: relative;
        width: auto;
        height: auto;
        margin-bottom: 28px;
      }

      h3 {
        color: var(--Contenidos, #131112);
        font-family: "Myriad Pro";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        @media only screen and (max-width: 992px) {
          text-align: center;
        }
      }
      p {
        color: var(--Contenidos, #131112);
        font-family: "Myriad Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        @media only screen and (max-width: 992px) {
          text-align: center;
        }
      }

      &__tooltip {
        border-radius: 20px;
        background: var(--Secundarios-Neutro-8, #F0F0F0);
        box-shadow: 0px var(--xs, 4px) var(--m, 16px) 0px rgba(128, 128, 128, 0.08);
        color: var(--Contenidos, #131112);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 28px;
        text-align: center;
        width: 305px;
        padding: 10px 0px;

        .mc-icon {
          display: inline-block;
          vertical-align: middle;
          // margin-top: -2px;
          // margin-right: 5px;
        }

        strong {
          color: #ED1C27;
          font-weight: bold;
        }
      }

      &__cta {
        width: 210px;
        button {
          height: 48px;
          padding-top: 2px;
          font-weight: 300;

          svg {
            path {
              stroke: #fff
            }
          }
        }
      }
    }
  }

  .active-item-0 .slick-track div[data-index="-1"],
  .active-item-0 .slick-track div[data-index="-2"] {
    opacity: 0;
  }

  .active-item-0 .slick-track div[data-index="1"] {
    top: 60px;
  }
  .active-item-0 .slick-track div[data-index="2"] {
    top: 120px;
  }
  .active-item-0 .slick-track div[data-index="3"] {
    top: 180px;
    opacity: 0;
  }
  .active-item-0 .slick-track div[data-index="4"] {
    top: 240px;
    opacity: 0;
  }

  .active-item-1 .slick-track div[data-index="0"],
  .active-item-1 .slick-track div[data-index="-1"],
  .active-item-1 .slick-track div[data-index="-2"] {
    opacity: 0;
  }
  .active-item-1 .slick-track div[data-index="2"] {
    top: 60px;
  }
  .active-item-1 .slick-track div[data-index="3"] {
    top: 120px;
  }
  .active-item-1 .slick-track div[data-index="4"] {
    top: 180px;
    opacity: 0;
  }
  .active-item-1 .slick-track div[data-index="5"] {
    top: 240px;
    opacity: 0;
  }

  .active-item-2 .slick-track div[data-index="-1"],
  .active-item-2 .slick-track div[data-index="0"],
  .active-item-2 .slick-track div[data-index="1"] {
    opacity: 0;
  }
  .active-item-2 .slick-track div[data-index="3"] {
    top: 60px;
  }
  .active-item-2 .slick-track div[data-index="4"] {
    top: 120px;
  }
  .active-item-2 .slick-track div[data-index="5"] {
    top: 180px;
    opacity: 0;
  }
  .active-item-2 .slick-track div[data-index="6"] {
    top: 240px;
    opacity: 0;
  }

  .active-item-3 .slick-track div[data-index="0"],
  .active-item-3 .slick-track div[data-index="1"],
  .active-item-3 .slick-track div[data-index="2"] {
    opacity: 0;
  }
  .active-item-3 .slick-track div[data-index="4"] {
    top: 60px;
  }
  .active-item-3 .slick-track div[data-index="5"] {
    top: 120px;
  }
  .active-item-3 .slick-track div[data-index="6"] {
    top: 180px;
    opacity: 0;
  }
  .active-item-3 .slick-track div[data-index="7"] {
    top: 240px;
    opacity: 0;
  }

  .active-item-4 .slick-track div[data-index="1"],
  .active-item-4 .slick-track div[data-index="2"],
  .active-item-4 .slick-track div[data-index="3"] {
    opacity: 0;
  }
  .active-item-4 .slick-track div[data-index="5"] {
    top: 60px;
  }
  .active-item-4 .slick-track div[data-index="6"] {
    top: 120px;
  }
  .active-item-4 .slick-track div[data-index="7"] {
    top: 180px;
    opacity: 0;
  }
  .active-item-4 .slick-track div[data-index="8"] {
    top: 240px;
    opacity: 0;
  }

  .active-item-5 .slick-track div[data-index="2"],
  .active-item-5 .slick-track div[data-index="3"],
  .active-item-5 .slick-track div[data-index="4"] {
    opacity: 0;
  }
  .active-item-5 .slick-track div[data-index="6"] {
    top: 60px;
  }
  .active-item-5 .slick-track div[data-index="7"] {
    top: 120px;
  }
  .active-item-5 .slick-track div[data-index="8"] {
    top: 180px;
    opacity: 0;
  }
  .active-item-5 .slick-track div[data-index="9"] {
    top: 240px;
    opacity: 0;
  }
}