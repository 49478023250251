.mc-login__header {
  text-align: center;
  padding: 20px 0px;
  padding-bottom: 0px;

  @media only screen and (min-width: 992px) {
    display: none !important;
  }

  .mc-app-container {
    padding: 0 10px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.mc-page  {
  &.login {
    padding-bottom: 8px;
    @media only screen and (min-width: 992px) {
      padding-top: 0px !important;
      background: whitesmoke;
    }

    .mc-login__container {
      position: relative;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;

      @media only screen and (min-width: 992px) {
        margin-bottom: -15px;
        padding-top: 0px;
        background: transparent !important;
        & > img {
          width: 100%;
          height: auto;
        }

        .mc-login__header {
          position: relative;
          display: block;
          box-sizing: border-box;
          padding: 0 18px;
          width: 1100px;
          max-width: 90%;
          margin: 0 auto;
          z-index: 2;
          background: transparent !important;
          text-align: right;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0px;
          bottom: 0px;
          height: 200px;
          transform: translate(0px, -105%);

          .mc-app-container {
            width: 400px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: auto;
            bottom: auto;
            height: auto;
            padding: 0px;

            .mc-app-rely {
              width: 400px;
              text-align: left;
              margin: 0 auto;

              img {
                width: 336px;
              }
              h1 {
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                margin-top: 0px;
                color: #2D2D2D;

                br {
                  display: none;
                }
              }
            }
          }
        }

        .mc-app-container {
          text-align: right;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0%;
          bottom: 0px;
          height: 450px;

          .mc-login {
            width: 50%;
            display: inline-block;
            vertical-align: top;

            .mc-app-container {
              text-align: left;

              .mc-login__form {
                margin-right: 0px;
              }
            }
          }
        }
      }

      @media only screen and (min-width: 1400px) {
        .mc-login__header {
          .mc-app-container {
            .mc-app-rely {
              img {
                width: 350px;
              }
            }
          }
        }
      }
    }
    .mc-section {
      margin-top: 30px;

      .mc-cardbox-container {
        background-color: transparent;
        img {
          background-color: transparent !important;
        }
        h2 {
          margin: 0px 0px !important;
          padding: 20px 0px !important;
        }
        h2, .desc-paraTodos {
          width: 100%;
          background-color: rgba(230, 230, 230, 0.6980392157);
          max-width: 100%;
          box-sizing: border-box;
        }
      }

      @media only screen and (min-width: 992px) {
        position: relative;
        display: block;
        width: 1100px;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0 18px;
        margin: -190px auto 0 auto;
        z-index: 2;
        // display: none !important;

        .mc-cardbox-container {
          height: auto;
        }
      }
      @media only screen and (max-width: 992px) {
        margin-bottom: 0px !important;
      }
    }
    
    .mc-slider-rotator {
      padding-right: 0px;

      .slick-slider {
        padding: 0 0px;
        .slick-list {
          .mc-cardbox-container {
            margin: 5px;
          }
        }
      }
    }
  }
  // &.mc-page-awards{
  //   .mc-page-content{
  //     min-height: 600px;
  //   }
  // }
}