.mc-page.mechanic {
  .mc-slider-rotator {
    padding-right: 0px;
    margin-top: 30px;
    padding-bottom: 0px;
  }

  @media (max-width: 768px) {  
    .mc-app-columns__item {
      position: relative;
      .mc-app-rely {
        width: 100%;

        .mc-app-centry {
          width: 100%;
        }
      }
    }
  }

  .slider-mechanic{
    margin: 0 auto;    
    max-width: 710px;
    @media (max-width: 768px) {  
      max-width: 100% !important;    
      width: 100% !important;

      .mc-slider-rotator {
        display: block;
        width: 100%;
        .slick-slider {
          width: 100% !important;
          .slick-list {
            .slick-track {
              width: auto !important;
              .slick-slide {
                display: block;
                width: 100% !important;
              }
            }
          }
        }
      }

      .slick-slider {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    @media (max-width: 768px) {      
      max-width: 500px;
    }
    @media (max-width: 576px) {      
      max-width: 390px;
    }
    @media (max-width: 490px) {      
      max-width: 300px;
    }
    @media (max-width: 300px) {      
      max-width: 250px;
    }

    .single-button {
      &:hover {
        border-radius: 110.345px;
        background: var(--Primario-Primario-oscuro, #C90C14);
        box-shadow: 0px 0px 8px 2px rgba(201, 12, 20, 0.55);
      }
    }
  }
  .title-mechanic{
    margin-top: -20px;

    @media only screen and (min-width: 992px) {
      position: relative;
      top: -60px;
    }
    .mc-page-content__separator{
      margin: 16px 0 0 0;
    }
    h1, .mc-page-content__separator, p{
      text-align: left;
      @media (max-width: 768px) {      
        text-align: center;
      }
    }
  }
}