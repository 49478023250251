.mc-pagger-container {
  position: relative;
  text-align: center;
  margin: 30px 0px;

  &__arrow {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 48px;
    margin: 0 10px;
    cursor: pointer;

    svg {
      position: relative;
      top: 4px;

      path {
        fill: #C90C14;
        stroke: #C90C14;
      }
    }

    &.right {
      transform: rotate(180deg);

    }
  }

  &__page {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;
    margin: 0 6px;
    cursor: pointer; 
    background-color: transparent;
    border-radius: var(--S, 8px);
    border: 1px solid transparent;
    color: #4D4D4D;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;

    &:hover {
      border: 1px solid #C90C14;
    }

    &.active {
      cursor: default;
      color: #fff;
      font-weight: 900;
      line-height: 20px;
      opacity: 1 !important;
      background: var(--Primario-Primario-oscuro, #C90C14);
      box-shadow: 0px 0px 4px 0px rgba(237, 28, 39, 0.45);

    }
  }
}