.mc-header-container {
  z-index: 3;
  position: absolute;
  left: 0px;
  width: 100%;
  top: 0px;

  &.mc-header-container--major-award {
    @media only screen and (max-width: 992px) {
      z-index: 99;
    }
  }

  &__menu {
    position: relative;
    height: 52px;

    @media only screen and (min-width: 992px) {
      z-index: 2;
      height: 54px;
      padding-top: 33px;
    }
  }

  &__submenu {
    position: absolute;
    height: 60px;
    background-color: $secondaryColorLight;
    -webkit-border-bottom-right-radius: 32px;
    -webkit-border-bottom-left-radius: 32px;
    -moz-border-radius-bottomright: 32px;
    -moz-border-radius-bottomleft: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 32px;
    z-index: 1;
    left: 0px;
    width: 100%;

    &.not-login {
      display: none;
    }

    .mc-preloader-container {
      top: -7px;
      transform: scale(0.7);
      @media only screen and (min-width: 992px) {
        top: -10px;
        transform: scale(0.7);
      }
    }

    @media only screen and (min-width: 992px) {
      width: 451px;
      left: 0px;
      right: 0px;
      margin: auto;
      height: 54px;
      transform: translate(282px, 0px);
      & > .mc-app-container {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &__logo--desktop {
    display: none !important;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 110px;
    z-index: 1;
    text-align: center;
    height: auto;
    max-width: 110px;

    img {
      width: 100%;
      height: auto;
    }

    @media only screen and (min-width: 992px) {
      display: block !important;
    }
  }

  &__logo {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 110px;
    z-index: 1;
    text-align: center;
    height: auto;
    max-width: 110px;

    img {
      width: 100%;
      height: auto;
    }

    @media only screen and (min-width: 992px) {
      display: none;
    }
  }

  &__mobile--close,
  &__mobile--notify {
    position: absolute;
    right: 20px;
    top: 0px;
    width: 50%;
    z-index: 1;
    text-align: right;
    height: 100%;
    max-width: 80px;
    cursor: pointer;

    .icon-close_session,
    .icon-notification {
      width: 26px;
      height: 26px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      top: 3px;

      &:hover {
        opacity: 0.7;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (min-width: 992px) {
      text-align: center;
      transform: scale(0.95);
    }
  }

  &__mobile--submenu {
    position: absolute;
    right: 20px;
    top: 0px;
    width: 50%;
    z-index: 1;
    text-align: right;
    height: 100%;
    max-width: 80px;
    cursor: pointer;

    .icon-menu {
      position: relative;
      top: 2px;
      transform: scale(0.9);
    }
  }

  &__nav {
    position: fixed;
    right: 0px;
    left: 0px;
    text-align: right;
    bottom: 0px;
    z-index: 10;
    background-color: #F0F0F0;
    height: 80px;
    overflow: hidden;
    border-top: 1px solid #B8B6B7;

    @media only screen and (min-width: 992px) {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 800px;
      left: auto;
      height: 100%;
      overflow: visible;
      margin: auto;

      border-radius: 100px;
      opacity: 0.9;
      background: #FFF;
      border-top: 0px;
      text-align: center;

    }

    @media only screen and (min-width: 992px) and (max-width: 1450px) {
      right: 62px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1320px) {
      right: 92px;
    }


    ul {
      padding: 0px;
      margin: 0px;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 2;
      text-align: center;

      @media only screen and (min-width: 992px) {
        text-align: center;
      }
      
      li {
        width: 18.7%;
        height: 100%;
        list-style: none;
        text-align: center;
        display: inline-block;
        vertical-align: top;

        &.mc-header-container__nav--item {
          .icon-alliances {
            svg {
              transform: scale(1.2) translate(0px, -1px);
            }
          }
          .icon-more {
            svg {
              transform: scale(1.2) translate(0px, -1px);
            }
          }
          
        }

        @media only screen and (min-width: 992px) {
          width: auto;
          float: none;
          display: inline-block;
          vertical-align: middle;
          padding: 0 0px;
          margin: 0 10px;
          position: relative;
          top: -0px;
        }

        a,
        .mc-header-container__nav--button {
          display: block;
          height: 100%;
          width: 100%;
          cursor: pointer;
          color: $colorTextL2;
          text-decoration: none;

          @media only screen and (max-width: 992px) {
            font-size: 12px;
          }

          &:hover {
            opacity: 0.7;
          }

          span {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: #131112;

            @media only screen and (min-width: 992px) {
              font-size: 16px;
              line-height: 20px;
              font-weight: 350;
              color: #353535;
              position: relative;
            }
          }

          .mc-icon {
            transform: scale(0.9);
            margin-bottom: -4px;
            @media only screen and (min-width: 992px) {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;
              margin-bottom: 0px;
              position: relative;
            }
          }
        }

        a, .mc-header-container__nav--button {
          .mc-app-centry {
            & > span {
              display: block;
              border-radius: 8px;
              padding-top: 5px;
              padding-bottom: 5px;
              margin: 0 5px;
              white-space: nowrap;

              @media only screen and (min-width: 992px) {
                padding-top: 7px;
              }
            }
          }
        }

        &.mc-header-container__nav--item-active {
          &:not(.mas) {
            width: 25%;

            @media only screen and (min-width: 992px) {
              width: auto;
            }
          }
          
          a {
            .mc-app-centry {
              & > span {
                background-color: #C90C14;
                color: #fff;
                box-shadow: 0px 0px 24px 0px rgba(201, 12, 20, 0.50);

                @media only screen and (min-width: 992px) {
                  padding-left: 20px;
                  padding-right: 20px;
                }
              }
              .icon-mechanic {
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
              .icon-progress {
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
              .icon-awards {
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
              .icon-alliances {
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
              .icon-awardsNew {
                color: #fff;
                svg {
                  stroke: transparent;
                  path {
                    stroke: #fff;
                    fill: transparent;
                  }
                }
              }
            }
          }
          .mc-header-container__nav--button {
            .mc-app-centry {
              & > span {
                background-color: #C90C14;
                color: #fff;
                box-shadow: 0px 0px 24px 0px rgba(201, 12, 20, 0.50);

                @media only screen and (min-width: 992px) {
                  padding-left: 20px;
                  padding-right: 20px;
                }
              }
            }
            .icon-more {
              svg {
                stroke: #fff;
                path {
                  stroke: #fff;
                }
                circle {
                  fill: #fff !important;
                }
              }
            }
          }
        }
      }
    }

    &--extra {
      position: fixed;
      right: -30px;
      bottom: 90px;      
      z-index: 1;
      box-sizing: border-box;
      padding: 12px 20px;
      text-align: right;
      min-width: 170px;
      border: 1px solid #F5BD47;
      visibility: hidden;
      opacity: 0;      
      border-radius: var(--l, 24px) var(--s, 8px) var(--l, 24px) var(--l, 24px);
      border: 1px solid var(--Secundarios-Neutro-7, #B8B6B7);
      background: var(--Secundarios-Neutro-9, #FFF);

      @media only screen and (min-width: 992px) {
        position: absolute;
        top: 66px;
        bottom: auto;
        right: 0px !important;
        outline: transparent;
        -webkit-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.1);

      }

      a,
      .mc-header-container__nav--close {
        display: block;
        padding: 8px 0px;
        text-decoration: none;
        cursor: pointer;
        & > span {
          font-size: 12px;
          font-style: normal;
          font-weight: 800;
          color: #353535;
        }
        .mc-icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 7px;
          transform: scale(0.9);
          margin-left: 8px;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      .mc-header-container__nav--close {                
        padding-top: 8px;
        color: #C30600;
        font-size: 12px;
        font-style: normal;
        line-height: 14px;
        font-weight: 800;

        .mc-icon {
          margin-right: 8px;
          position: relative;
        }
      }

      &.opened {
        visibility: visible;
        opacity: 1;
        right: 10px;
      }

    }
  }

  &__summary {
    position: absolute;
    right: 0px;
    top: 14px;
    height: 64px;
    width: 158px;
    -webkit-border-top-left-radius: 200px;
    -webkit-border-bottom-left-radius: 200px;
    -moz-border-radius-topleft: 200px;
    -moz-border-radius-bottomleft: 200px;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 10px;
    box-shadow: 0px var(--XS, 4px) 34px 0px rgba(77, 77, 77, 0.30);

    @media only screen and (max-width: 992px) {
      height: 46px;
      top: 28px;
    }

    .mc-preloader-container {
      transform: scale(0.8);
      top: -3px;
    }

    @media only screen and (min-width: 992px) {
      top: 49px;
      height: 38px;
      position: absolute;
    }

    .mc-app-centry {
      position: relative;
      top: -2px;
    }

    &--item {
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      height: 24px;
      border-radius: 40px;
      background-color: gray;
      text-align: center;
      margin: 0 4px;
      color: var(--Secundarios-Neutro-9, #FFF);
      text-align: center;
      font-family: 'Myriad Pro Bold';
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 23px;

      .mc-icon {
        display: inline-block;
        vertical-align: middle;
        transform: scale(0.9);
        position: relative;
        top: 1px;
      }

      &.amount {
        border-radius: var(--Size-2XL, 24px);
        background: var(--Primario-Primario-oscuro, #C90C14);
        box-shadow: 0px 0px 4px 0px #ED1C27;
        top: 2px;

        svg {
          path {
            fill: #fff;
          }
        }
      }
      &.trx {
        background: var(--Terciarios-Naranja-1, #F8991D);
        box-shadow: 0px 0px var(--M, 16px) 0px #F99F1C;
        color: #131112;
        top: 2px;
      }
    }

    a {
      color: var(--Primario-Primario-oscuro, #C90C14);
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-decoration: underline;
      margin-left: 8px;
      position: relative;
      top: 2px;
    }

    .tooTip-progress {
      position: absolute;
      top: -15px;
      right: -10px;
      box-sizing: border-box;
      padding: 1px 7px;
      border-radius: 8px 8px 0px 0px;
      background: #f7f2ea;

      color: var(--Contenidos, #131112);
      font-family: "Myriad Pro";
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 160% */
    }
  }

  &__vector {
    position: absolute;
    right: 5px;
    top: 100px;
    height: auto;
    width: 9px;

    img {
      width: 100%;
      height: auto;
    }

    @media only screen and (min-width: 992px) {
      display: none !important;
    }
  }

  &.mc-header-container-page-login {
    .mc-header-container__menu {

      @media only screen and (min-width: 992px) {
        background-color: transparent !important;
        height: 110px;

        &::before {
          display: none;
        }

        .mc-header-container__logo {
          background-color: transparent !important;
        }
      }
    }
    .mc-header-container__mobile--submenu,
    .mc-header-container__nav {
      display: none !important;
    }
    .mc-header-container__submenu {
      display: none !important;
    }
  }

  &.mc-header-container-page-progreso,
  &.mc-header-container-page-login {
    .mc-header-container__summary {
      display: none;
    }
    .mc-header-container__vector {
      top: 45px;
    }
    @media only screen and (min-width: 992px) {
      .mc-header-container__submenu,
      .mc-header-container__vector  {
        display: none !important;
      }
    }
  }

  &.mc-header-container-type-2 {

    .mc-header-container__menu {
      text-align: center;

      @media only screen and (min-width: 992px) {
        height: 110px;
      }

      .mc-header-container__logo {
        left: 0px;
        right: 0px;
        margin: auto;

        @media only screen and (min-width: 992px) {
          height: 50%;
          width: 100%;
          max-width: 230px;
        }
      }

      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          left: 0px;
          right: 0px;
          text-align: center;
          top: auto;
          bottom: 0px;
          height: 50%;

          .mc-header-link {
            line-height: 52px;
          }

          .mc-header-container__nav--extra {
            right: -32% !important;
            left: 0;
            width: 300px;
            margin: auto;
            top: 55px;
          }
        }
        ul {
          li {
            .mc-icon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;
            }

            .mc-app-centry {
              & > span {
                display: inline-block;
                padding: 5px 7px;
              }
            }

            &.mc-header-container__nav--item-active {
              background-color: transparent;

              .mc-app-centry {
                & > span {
                  background-color: #000;
                  color: #fff;

                  .mc-icon {
                    svg {
                      path {
                        fill: #fff !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mc-header-container-type-3 {

    .mc-header-container__menu {
      text-align: left;
      @media only screen and (min-width: 992px) {
        background-color: transparent !important;
      }

      .mc-header-container__logo {
        left: 20px;
        right: auto;
        margin: auto;
      }

      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          background-color: rgb(141, 141, 141);
          right: 0px;
          left: auto;
          width: auto;
          -webkit-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.3);
          -moz-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.3);

          .mc-header-container__nav--extra {
            right: 0px !important;
          }
        }

        ul {
          li {

            a,
            .mc-header-container__nav--button {
              &:hover {
                opacity: 1;
              }
            }

            .mc-header-container__nav--button {
              .mc-app-centry {
                & > span {
                  .mc-icon {
                    display: none !important;

                    @media only screen and (min-width: 992px) {
                      display: inline-block !important;
                      position: relative;
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }
            
            .mc-icon {
              position: absolute;
              left: 0px;
              right: 0px;
              top: -26px;
              background-color: #fff;
              border-radius: 100px;
              width: 40px;
              height: 40px;
              text-align: center;
              margin: auto;
              -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
              -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
              box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
              opacity: 0;

              @media only screen and (min-width: 992px) {
                position: relative;
                top: auto;
                background-color: transparent;
                -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0);
                -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0);
                box-shadow: 0px 3px 8px 0px rgba(0,0,0,0);
                opacity: 1;
                width: 35px;
                height: 35px;
                top: 4px;
              }

              & > span {
                position: relative;
                top: 8px;

                @media only screen and (min-width: 992px) {
                  top: auto;
                }
              }
            }

            .mc-app-centry {
              & > span {
                display: inline-block;
                padding: 5px 7px;

                @media only screen and (min-width: 992px) {
                  top: -4px;
                  position: relative;
                }
              }
            }

            &.mc-header-container__nav--item-active {
              background-color: transparent;

              .mc-app-centry {
                & > span {
                  color: #fff;

                  .mc-icon {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mc-header-container-type-4 {

    @media only screen and (min-width: 992px) {
      z-index: 9;
    }

    @media only screen and (min-width: 992px) {
      .mc-header-container__menu {
        .mc-header-container__logo {
          left: 0px;
          right: 0px;
          margin: auto;
        }
        .mc-header-container__mobile--submenu {
          .mc-icon {
            svg {
              transform: scale(0.85);
            }
          }
        }

        .mc-header-container__nav {
          ul {
            @media only screen and (min-width: 992px) {
              padding-right: 10px;
            }
          }
        }
      }
    }

    .mc-header-container__nav {
      position: fixed;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 999;
      height: auto;
      width: auto;
      background-color: transparent;
      visibility: hidden;
      -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
      -o-transition: all .3s cubic-bezier(.77,0,.175,1);
      transition: all .3s cubic-bezier(.77,0,.175,1);

      .mc-header-container__mobile--overlay {
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        z-index: 1;
        background-color: rgba(0,0,0,0.6);
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);
        visibility: hidden;
        opacity: 0;
      }

      .mc-header-container__nav--close {
        position: absolute;
        right: -15px;
        top: 12px;
        color: $colorText;
        display: block;
        text-align: right;
        z-index: 3;
        padding: 16px 26px;
        box-sizing: border-box;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);
      }

      ul {
        background-color: #fff;
        right: -280px;
        top: 0px;
        width: 280px;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        padding-top: 60px;
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        transition: all .3s cubic-bezier(.77,0,.175,1);
        visibility: hidden;
        opacity: 0;

        li {
          float: none;
          display: block;
          width: 100%;
          box-sizing: border-box;
          height: auto;
          text-align: right;
          padding: 0 30px;

          a {
            height: 60px;

            .mc-app-centry {
              & > span {
                color: $colorText;
                text-align: left;

                .mc-icon {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;

                  svg {
                    path {
                      stroke: $colorText;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .mc-header-container__nav--extra {
        display: none !important;
      }
    }

    &.show-menu {
      .mc-header-container__nav {
        visibility: visible !important;

        .mc-header-container__mobile--overlay,
        .mc-header-container__nav--close {
          visibility: visible !important;
          opacity: 1 !important;
          right: 0px !important;
        }

        ul {
          right: 0px;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.mc-header-link {
  line-height: 65px;
  font-size: 15px;
  font-weight: 600;
}