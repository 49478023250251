.mc-footer-container {
  position: relative;
  background-color: #fff;
  padding-bottom: 80px;
  padding-top: 20px;

  @media only screen and (min-width: 992px) {
    padding-bottom: 0px;
  }
  @media only screen and (max-width: 992px) {
    padding-top: 0px;
  }

  &__cards {
    margin: 0 auto;
    margin-bottom: -97px;
    width: 100%;
    max-width: 325px;
    display: block;
    position: relative;
    top: -57px;

    @media only screen and (min-width: 992px) {
      width: 344px;
      margin-bottom: -52px;
    }
  }

  &__bg {
    padding-bottom: 40px;
    padding-top: 50px;
    background-color: #F0F0F0;
    border-top: 2px solid #ED1C27;
  }

  &__section {
    border-bottom: 1px solid #808080;
    margin: 6px 36px;
    padding: 16px 0px;
    @media only screen and (min-width: 992px) {
      border-bottom: 0px;
      padding-bottom: 0px;
    }

    &--links {
      text-align: center;
      margin-bottom: 0px;
      position: relative;
      padding: 0px;
      margin: 20px auto;
      max-width: 340px;
      margin-left: -24px;
      margin-right: -24px;
      box-sizing: border-box;
      @media only screen and (max-width: 992px) {
        max-width: 340px;
        width: auto;
        display: block;
      }
      @media only screen and (min-width: 992px) {
        margin: 0 auto;
        margin-bottom: 30px;
      }

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        color: #131112;
        display: inline-block;
        width: 50%;
        margin: 0px;
        position: relative;

        &:nth-child(2) {
          &::before {
            content: '';
            width: 1px;
            height: 15px;
            background-color: #131112;
            position: absolute;
            left: 4px;
            top: 0px;
            bottom: 0px;
            margin: auto;
          }
        }
      }
    }

    div {
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 28px 0;
        color: #131112;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
    
  }

  &__subbg {
    background-color: #fff;
    padding: 0px 0px;
    text-align: center;

    img {
      width: 100%;
      height: auto;
      max-width: 390px;
    }
  }

  &__image {
    width: 200px;
    height: auto;
    display: block;
    margin: 24px auto;
  }

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5px;
    position: relative;
    color: #131112;
    @media only screen and (min-width: 992px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    p {
      padding: 0 40px;
      text-align: justify;

      @media only screen and (min-width: 992px) {
        padding: 0 0px;
      }

      i, a {
        color: var(--Contenidos, #131112);

        /* CAP/c-Regular */
        font-family: "Myriad Pro";
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: auto;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
      i {
        text-decoration: none !important;
      }
    }
  }

  .mc-footer-vigilated {
    position: absolute;
    left: 20px;
    bottom: 0px;
    width: 18px;
    z-index: 1;
    height: auto;

    @media only screen and (min-width: 992px) {
      left: -30px;
      position: absolute;
      bottom: 0px;
    }
  }

  .mc-footer-container__new {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: block;
  }

  &.mc-footer-container-type-2 {

    .mc-footer-container__cards {
      position: relative;
      top: auto;
      margin-bottom: 0px;
    }

    .mc-footer-container__bg {
      padding: 30px 0px;
      text-align: center;

      &--image {
        width: 100%;
        height: auto;
        max-width: 400px;
        margin-bottom: 8px;

        @media only screen and (min-width: 992px) {
          position: absolute;
          left: 20px;
          max-width: 250px;
        }
      }

      .mc-footer-container__sections {
        text-align: left;

        @media only screen and (min-width: 992px) {
          display: block;
          padding-left: 380px;
          box-sizing: border-box;
        }

        &--section {
          margin-bottom: 36px;

          h4 {
            margin-bottom: 10px;
            color: #fff;
          }
          ul {
            margin: 0px;

            a {
              display: block;
              width: 100%;
              text-align: left;
              font-size: 16px;
              line-height: 22px;
              text-decoration: none;
              cursor: pointer;
              margin-bottom: 10px;

              &:hover {
                text-decoration: underline;
                opacity: 0.7;  
              }

              &::before {
                display: none !important;
              }
            }
          }

          p {
            margin-top: 0px;
            color: #fff;
          }

          @media only screen and (min-width: 992px) {
            position: relative;
            margin-bottom: 22px;

            h4 {
              position: absolute;
              top: 0px;
              margin: 0px !important;
            }
            ul, p {
              display: block;
              max-width: 100%;
              text-align: left;
              padding-left: 130px;
              a {
                display: inline-block;
                vertical-align: middle;
                width: auto;
                margin-right: 20px;
              }
            }
          }
        }
      }

      .mc-footer-container__image {
        text-align: left;
        display: block;
        margin: 20px 0px;
        margin-bottom: 6px;

        @media only screen and (min-width: 992px) {
          margin-top: -28px;
        }
      }
    }

    .mc-footer-vigilated {
      @media only screen and (min-width: 992px) {
        bottom: 0px;
      }
    }
  }

  &.mc-footer-container-type-3 {

    .mc-footer-container__bg {
      padding-top: 50px;

      @media only screen and (min-width: 992px) {
        position: relative;
      }
    }

    .mc-footer-container__cards {
      width: 200px;
      max-width: 350px;
      top: 0px;
      margin-bottom: 28px;
    }

    .mc-footer-container__bg--image {
      display: block;
      margin: 20px auto;
      width: 250px;

      @media only screen and (min-width: 992px) {
        margin-top: 0px;
        position: relative;
        left: 8%;
      }
    }

    .mc-footer-container__image {
      margin-bottom: 0px;

      @media only screen and (min-width: 992px) {
        position: absolute;
        right: 20px;
        top: 0px;
        margin-top: 0px;
        width: 160px;
      }
    }

    .mc-footer-container__section--links {
      margin: 28px auto;
      margin-top: 0px;

      @media only screen and (min-width: 992px) {
        a:nth-child(2)::before {
          left: -18px;
        }
      }

    }

    .mc-footer-container__section {
      border: 0px !important;

      @media only screen and (min-width: 992px) {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        padding-top: 0px;

        .mc-footer-container__section--links {
          text-align: left;
          width: 50%;
          position: absolute;
          left: 20px;
          top: 0px;
        }
      }
    }

    .mc-footer-container__subbg {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background-color: transparent !important;
      border-top: 1px solid rgba(255,255,255,0.3);
      margin-top: 40px;
      color: #fff;
      
    }

    .mc-footer-container__text  {
      @media only screen and (min-width: 992px) {
        padding-top: 35px;
      }
    }

    .mc-footer-container__cards {
      @media only screen and (min-width: 992px) {
        display: none !important;
      }
    }
  }

  &.mc-footer-container-type-5 {
    margin-top: 0px;

    .mc-footer-container__bg {
      &--image {
        margin-bottom: 0px !important;
      }
    }

    .mc-footer-container__cards {
      position: relative;
      top: -68px;
      margin-bottom: 0px;
      width: 230px;
    }

    .mc-footer-container__bg {
      padding: 30px 0px;
      padding-top: 0px;
      margin-top: -58px;
      text-align: center;

      @media only screen and (min-width: 992px) {
        margin-top: -28px;
      }

      .mc-app-container:nth-child(1) {
        .mc-footer-container__sections {
          text-align: center;
          padding: 0 40px;

          @media only screen and (min-width: 992px) {
            padding: 0;
            padding-left: 48%;
          }

          .mc-footer-container__sections--section {
            h4 {
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 20px;

              @media only screen and (min-width: 992px) {
                position: relative;
                text-align: left;
                margin-bottom: 28px !important;
              }
            }
            ul {
              @media only screen and (min-width: 992px) {
                padding: 0px;
              }

              a {
                text-align: center;
                margin-bottom: 20px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 350;

                @media only screen and (min-width: 992px) {
                  margin-bottom: 16px;
                }
              }
            }

            p {
              font-weight: 350;

              @media only screen and (min-width: 992px) {
                padding: 0px;
                margin-bottom: 16px;
              }
            }

            &:last-child {
              margin-bottom: 0px;
            }

            @media only screen and (min-width: 992px) {
              display: inline-block;
              width: 50%;
              vertical-align: top;
            }
          }
        }
      }

      &--image {
        width: 100%;
        height: auto;
        max-width: 400px;
        margin-bottom: 8px;

        @media only screen and (min-width: 992px) {
          position: absolute;
          left: 20px;
          max-width: 345px;
        }
      }

      .mc-footer-container__sections {
        text-align: left;

        @media only screen and (min-width: 992px) {
          display: block;
          padding-left: 380px;
          box-sizing: border-box;
        }

        &--section {
          margin-bottom: 36px;

          h4 {
            margin-bottom: 10px;
            color: #fff;
          }
          ul {
            margin: 0px;

            a {
              display: block;
              width: 100%;
              text-align: left;
              font-size: 16px;
              line-height: 22px;
              text-decoration: none;
              cursor: pointer;
              margin-bottom: 10px;

              &:hover {
                text-decoration: underline;
                opacity: 0.7;  
              }

              &::before {
                display: none !important;
              }
            }
          }

          p {
            margin-top: 0px;
            color: #fff;
          }

          @media only screen and (min-width: 992px) {
            position: relative;
            margin-bottom: 22px;

            h4 {
              position: absolute;
              top: 0px;
              margin: 0px !important;
            }
            ul, p {
              display: block;
              max-width: 100%;
              text-align: left;
              padding-left: 130px;
              a {
                display: inline-block;
                vertical-align: middle;
                width: auto;
                margin-right: 20px;
              }
            }
          }
        }
      }

      .mc-footer-container__image {
        text-align: left;
        display: block;
        margin: 20px 0px;
        margin-bottom: 6px;

        @media only screen and (min-width: 992px) {
          margin-top: -28px;
        }
      }

      .mc-footer-container__text {
        & > div {
          border-top: 1px solid #fff;
        }
        p {
          padding: 0 0px;
          text-align: justify;
          font-size: 16px;
          line-height: 22px;
          font-weight: 350;
        }
      }
    }

    .mc-footer-vigilated {
      -webkit-filter: invert(0%);
      filter: invert(0%);
      top: -292px;
      bottom: auto;
      width: 26px;

      @media only screen and (min-width: 992px) {
        bottom: 0px;
        top: auto;
        left: -40px;
      }
    }
  }
}