.mc-davipuntos {
  position: relative;
  height: 360px;
  // background: linear-gradient(180deg, var(--Secundarios-Neutro-8, #F0F0F0) 0.13%, var(--Secundarios-Neutro-7, #B8B6B7) 189.28%);
  // margin-bottom: 64px;
  margin-top: 50px;
  background-image: 
  url('../../../public/assets/davipuntos-logo/Modo_de_aislamiento-1.png'), 
  url('../../../public/assets/davipuntos-logo/Modo_de_aislamiento-3.png');
  background-repeat: no-repeat;
  background-position: 
  top right,
  left center;

  .svg-mobile {
    display: none;
  }

  &.mc-davipuntos-page-terminos-y-condiciones,
  &.mc-davipuntos-page-preguntas-frecuentes {
    display: none !important;
  }

  @media only screen and (max-width: 992px) {
    height: auto;

    .svg-mobile {
      display: block;
      margin-bottom: 10px;

      svg {
        display: block;
        width: 100%;
        position: relative;
        top: -4px;
      }
    }
  }

  &__col-left,
  &__col-right {
    height: 100%;
    position: relative;
    width: 50%;
    float: left;

    @media only screen and (max-width: 992px) {
      float: none;
      height: auto;
      width: 100%;
    }

    svg {
      margin-top: -4px;

      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    
    .mc-button-container {
      margin-top: 20px;
      width: 200px;
      position: relative;
      top: 12px;

      button {
        height: 56px;
      }

      @media only screen and (max-width: 992px) {
        top: auto;
        margin-top: 30px;
        width: 100%;
      }
    }
  }

  &__col-right {
    @media only screen and (max-width: 992px) {
      padding: 14px 0px;
      padding-bottom: 28px;
    }
  }

  &__item {
    position: relative;
    margin: 10px 0px;

    div {
      &:nth-child(1) {
        color: var(--Secundarios-Neutro-2, #131112);
        font-family: 'Myriad Pro Black';
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;
        padding-right: 140px;

        @media only screen and (max-width: 992px) {
          padding-right: 90px;
        }
      }
      &:nth-child(2) {
        color: var(--Secundarios-Neutro-3, #231F20);
        font-family: "Myriad Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-top: 8px;

        @media only screen and (max-width: 992px) {
          padding-right: 90px;
        }
      }

      strong {
        font-family: 'Myriad Pro Bold';
      }
    }

    span {
      position: absolute;
      right: 0px;
      top: 18px;
      font-family: 'Myriad Pro Black';
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
      color: #009088;

      @media only screen and (max-width: 992px) {
        top: 34px;
      }

      &.red {
        color: #ED1C27;

        @media only screen and (max-width: 992px) {
          top: 24px;
        }
      }

    }
  }
}