.mc-dashboard-module {
  background-color: #fff;
  margin-bottom: 28px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.15);
  box-sizing: border-box;
  padding: 20px;
  margin-left: 1%;
  margin-right: 1%;

  h4 {
    margin: 0px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(231, 231, 231);
    padding-bottom: 15px;
  }

  &__content {
    padding: 2px 0px;

    .table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      
      td, th {
        border: 1px solid #f2f2f2;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      span {
        font-size: 12px;
        font-weight: 600;
        padding: 2px 4px;
      }

      .table-enable {
        background-color: rgb(229, 255, 229);
        border: 1px solid rgb(0, 186, 19);
        color: rgb(0, 186, 19);
      }
      .table-unabled {
        background-color: rgb(255, 229, 229);
        border: 1px solid rgb(186, 0, 0);
        color: rgb(186, 0, 0);
      }
      .table-inactive {
        background-color: rgb(247, 247, 247);
        border: 1px solid rgb(155, 155, 155);
        color: rgb(155, 155, 155);
      }
      .table-unabled-temp {
        background-color: rgb(255, 249, 229);
        border: 1px solid rgb(186, 155, 0);
        color: rgb(186, 143, 0);
      }

      &.table-min {
        td {
          font-size: 13px;
        }
      }

      .head_hight {
        background: #dbdbdb;
      }
    }

    .mc-dashboard-editor {
      background: #f7f7f7;
      box-sizing: border-box;
      padding: 3px;

      .rdw-editor-main {
        background-color: #fff;
        box-sizing: border-box;
        margin: 0 3px;
      }
    }

    .mc-dashboard-faqs {

      &__item {
        border-bottom: 1px solid rgb(212, 212, 212);
        margin-bottom: 30px;
        padding-bottom: 20px;
        position: relative;

        button {
          position: absolute;
          right: 0px;
          top: 0px;
          background-color: $errorColorLight;
          color: $errorColor;
          border-radius: 3px;
          border: 1px solid $errorColor;
          cursor: pointer;
          padding-bottom: 3px;

          &:hover {
            background-color: $errorColor;
            color: #fff;
          }
        }
      }
    }
  }

  &__actions {
    position: relative;
    border-top: 1px solid rgb(231, 231, 231);
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;

    .mc-button-container {
      width: auto;
      display: inline-block;
      vertical-align: top;
      width: 155px;

      button {
        padding: 0 12px;
        font-size: 14px;
      }

      &:nth-child(2) {
        margin-left: 6px;
      }
    }
  }

  &.col-2 {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-left: 1%;
    margin-right: 1%;

    @media only screen and (max-width: 992px) {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}