.image-slider {
  position: relative;
  width: 100%;
  height: 400px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  /* Añadir bordes redondeados */
  outline: none;
  /* Evitar un contorno por defecto */
}

.carousel {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item {
  position: absolute;
  transition: transform 0.5s ease, opacity 0.5s ease;
  -webkit-transition: transform 0.5s ease, opacity 0.5s ease;
  -moz-transition: transform 0.5s ease, opacity 0.5s ease;
  -ms-transition: transform 0.5s ease, opacity 0.5s ease;
  -o-transition: transform 0.5s ease, opacity 0.5s ease;
}

.carousel-box {
  
  width: 250px;
  height: 301px;
  text-align: center;
  transition: transform 0.3s ease;
  position: relative;

  .contentImg {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;

    h4 {
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 23.333px;
      margin: 0;
      margin-bottom: 13.5px;
    }

    p.desc {
      color: #FFF;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.333px;
      margin: 0;
      padding-bottom: 20px;
    }
  }
}

.carousel-box img {
  width: 100%;
}



.controls{
  display: flex;
  gap: 16px;
  @media (max-width: 992px) {
   justify-content: center;
  }
  .nav-button {
    background: none;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
    width: 32px;
    img{
      width: 100%;
    }
  }
}