.mc-page.progress {
  
  .mc-page-progress {
    &__titles {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 28px;
    }

    &__subtitles {
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 24px;
      margin-top: 26px;
      text-align: center;
      margin-bottom: 26px;

      span {
        font-weight: 350;
        display: block;
      }

      &.type-1 {
        border-bottom: 1px solid #BCBCBC;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        padding-bottom: 7px;
        color: #5B5B5B;
        margin-bottom: 0px;

        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
          position: relative;
          top: -1px;
        }
      }

      &.type-2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        padding-bottom: 0px;
        color: #5B5B5B;
        margin-top: 10px;
        margin-bottom: 0px;

        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
          position: relative;
          top: -1px;
        }
      }
    }

    &__extras {
      text-align: center;
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 14px;
        margin: 6px 0px;

        &:first-child {
          margin-bottom: 20px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 350;
      line-height: 20px;
    }
  }

  .mc-section.isLight {
    @media only screen and (min-width: 992px) {
      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px;
        margin-bottom: 2px;
      }
      p {
        text-align: center;
      }
    }
  }

  .mc-page-bg {
    padding-bottom: 0px;
    @media only screen and (max-width: 992px) {
      background-image: url('../../../public/assets/images/progress_bg_mobile.png');
      background-position: center top;
      background-size: 100% auto;
    }
    @media only screen and (min-width: 992px) {
      background-image: url('../../../public/assets/images/progress_bg_desktop.png');
      background-position: center top;
      background-size: 100% auto;
      -webkit-border-bottom-right-radius: 32px;
      -webkit-border-bottom-left-radius: 32px;
      -moz-border-radius-bottomright: 32px;
      -moz-border-radius-bottomleft: 32px;
      border-bottom-right-radius: 32px;
      border-bottom-left-radius: 32px;
      overflow: hidden;
      padding-top: 20px;
    }
    .mc-section  {
      margin-bottom: 0px;
      & > .mc-app-container {
        @media only screen and (min-width: 992px) {
          background-color: transparent;
          padding-top: 0px;
          .mc-page-progress__titles,
          .mc-page-progress__subtitles,
          .mc-progressBar-container,
          .mc-goal-counter,
          .mc-page-progress__extras {
            width: 35%;
          }
          .mc-progressBar-container,
          .mc-goal-counter {
            width: 395px;
            transform: translate(-299px, 0px);
          }
          .mc-page-progress__new_styles {
            width: 370px;
            margin-left: 30px;

            .mc-page-progress__heart {
              .mc-progressBar-container {
                transform: translate(0px, 0px) !important;
                width: 300px !important;
              }
            }
          }
          .mc-page-progress__subtitles.type-1 {
            display: inline-block;
            vertical-align: top;
            width: 200px;
            margin-top: 0px;
            margin-left: 25px;
            border-bottom: 0px;
            border-right: 1px solid #BCBCBC;
            margin-right: 8px;
          }
          .mc-page-progress__subtitles.type-2 {
            display: inline-block;
            vertical-align: top;
            width: 200px;
            margin-top: 0px;
          }
          .mc-page-progress__extras {
            text-align: left;
            margin-left: 38px;
            padding-bottom: 50px;
          }
        }
      }
    }
  }

  .mc-page-progress__desktop-banner {
    display: none;
    position: absolute;
    right: 0px;
    left: 0px;
    margin: auto;
    top: 82px;
    width: 600px;
    bottom: -41px;
    z-index: 1;
    transform: translate(210px, 0px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;

    @media only screen and (min-width: 992px) {
      display: block;
    }
  }

  .mc-section {
    .mc-app-container {
      background: #fbf9f5;
      -webkit-border-top-left-radius: 30px;
      -webkit-border-top-right-radius: 30px;
      -moz-border-radius-topleft: 30px;
      -moz-border-radius-topright: 30px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding-top: 26px;
    }
  }

  .mc-page-progress__new_styles {
    background: #FFFAEE;
    padding: 16px;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    margin: 20px 0;
    height: 140px;
    position: relative;

    .mc-page-progress__heart {
      position: absolute;
      left: 16px;
      top: 16px;
      width: 95px;
      height: 95px;
      z-index: 1;
    }
    .mc-page-progress__labels {
      position: absolute;
      right: 16px;
      left: 122px;
      top: 16px;
      bottom: 16px;

      div {
        strong {
          color: #E66442;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 24px;
        }
        &:first-child {
          color: #5B5B5B;
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 28px;
          margin-bottom: 8px;
        }
        &:nth-child(2) {
          color: #5B5B5B;
          font-size: 20px;
          font-style: normal;
          font-weight: 350;
          line-height: 24px;
        }
        &:nth-child(3) {
          color: #5B5B5B;
          font-size: 20px;
          font-style: normal;
          font-weight: 350;
          line-height: 24px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          border-top: 1px solid #E66442;
          padding: 10px 0px;
        }
      }
    }
    .mc-page-progress__percent {
      position: absolute;
      left: 16px;
      bottom: 16px;
      width: 95px;
      height: 46px;
      text-align: center;

      .mc-app-centry {
        color: #5B5B5B;
        text-align: center;
        font-size: 28.408px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}