.mc-post-redemtion {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;

  &__overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    background: rgba(237, 28, 39, 0.80);
    backdrop-filter: blur(2px);
  }
  &__container {
    width: 358px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    outline: 1px solid #fff;
  }
  &__window {
    border-radius: 24px;
    background: var(--Secundarios-Neutro-9, #FFF);
    padding: 24px;
    box-sizing: border-box;

    h3 {
      color: var(--Secundarios-Neutro-3, #231F20);
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px; /* 133.333% */
      margin-bottom: 8px;
    }
    .separator-title {
      width: 100%;
      height: 2px;
      margin-bottom: -6px;
    }
    .close-post-redemtion {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 1;
      cursor: pointer;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  &__slider {
    width: 310px;
    display: block;
    position: relative;
    border-radius: 0px 0px 16px 16px;
    border: 1px solid #eaeaea;
    background: linear-gradient(2deg, #F0F0F0 -0.89%, #FDFDFD 51.29%, #FFF 106.95%);
    box-shadow: 0px 115px 32px 0px rgba(135, 135, 135, 0.00), 0px 74px 29px 0px rgba(135, 135, 135, 0.01), 0px 41px 25px 0px rgba(135, 135, 135, 0.05), 0px 18px 18px 0px rgba(135, 135, 135, 0.09), 0px 5px 10px 0px rgba(135, 135, 135, 0.10);
    margin-bottom: 60px;

    h4 {
      color: var(--Secundarios-Neutro-3, #231F20);
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 110% */
      margin: 0px;
    }
    p {
      color: var(--Secundarios-Neutro-4, #4D4D4D);
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
    }
    img {
      width: 80px;
      height: auto;
      display: block;
      margin: 0 auto;
      margin-bottom: 12px;
    }

    .slick-dots {
      li {
        width: 24px;
        height: 2px;
        border-radius: var(--xxs, 2px);
        background: var(--Primario-Primario, #ED1C27);

        &.slick-active {
          width: 40px;
          transform: scale(1.2, 2);
        }

        button {
          width: 100%;
          height: 100%;
          margin: 0px;
          padding: 0px;
          border: 0px;
          opacity: 0;
          &:before {
            display: none;
          }
        }
      }
    }

    .slick-arrow {
      display: block;
      bottom: -38px;
      top: auto;
      left: 0px;
      right: 0px;
      margin: auto;
      background-image: url('../../../public/assets/images/arrow-L.png');
      width: 18px;
      height: 18px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 4;

      &:before {
        display: none;
      }

      &.slick-prev {
        left: -132px;
      }
      &.slick-next {
        right: -132px;
        transform: translate(0, -50%) rotate(180deg);
      }
    }

  }
}