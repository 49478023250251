.mc-button-container {
  display: block;
  width: 100%;
  text-align: center;

  button {
    display: block;
    width: 100%;
    border: 0px;
    height: 45px;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px; 
    cursor: pointer;
    border-radius: 8px;

    .inline-loader {
      width: 30px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }

    .mc-icon {
      transform: scale(1.14);
      top: 0px;
      margin-right: 8px;
      margin-bottom: -1px;
    }
    &.fit-content{
      width: fit-content;
    }

    &.button-quiz{
      width: 233px;
      font-weight: 100;
    }
  }

  &__primary {
    button {
      background-color: $primaryColor;
      color: #fff;

      &:hover {
        background-color: $primaryColorHover;
      }
      &.mc-button-container__status--inactive {
        background-color: #F0F0F0;
        cursor: no-drop;
        color: #4D4D4D;
      }
    }
  }

  &__secondary { 
    button {
      background-color: #fff;
      color: $primaryColor;;
      border: 1px solid $primaryColor;

      &:hover {
        background-color: $primaryColorLight;
        color: #fff;
      }
      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }

  .mc-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    transform: scale(1.2);
    position: relative;
    top: 1px;
  }
}