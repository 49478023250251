.mc-alert-message {
  position: relative;
  border: 1px solid rgb(184, 184, 184);
  background-color: rgb(245, 245, 245);
  padding: 18px 22px;
  display: block;
  margin: 30px 0px;

  h3 {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  p {
    margin: 16px 0px;
    
    &:last-child {
      margin-bottom: 10px;
    }
  }

  &__error {
    border: 1px solid $errorColor;
    background-color: $errorColorLight;

    h3 {
      color: $errorColor;
      font-weight: 600;
      font-size: 26px;
    }
  }

  &.blocked {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}