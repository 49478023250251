.SectionTYC {
  margin: 44px 0 88px 0;
  margin-top: 68px;
  @media (max-width: 768px) {
    margin: 88px 0 88px 0;
   
  }
  .wrap-tyc {
    max-width: 700px;
    display: flex;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    gap: var(--2xl, 40px);
    margin: 0 auto;

    border-radius: var(--l, 24px);
    background: var(--Primario-Primario-oscuro, #C90C14);

    @media (max-width: 768px) {
     flex-direction: column;
     gap: var(--xs, 4px);
     padding: var(--l, 24px) var(--ml, 20px);
    }
    .icon-tyc {
      img {
        max-width: 106px;
      }
    }

    p.description-tyc, a {
      color: var(--Secundarios-Neutro-9, #FFF);
      margin: 0;
      font-family: 'Myriad Pro Semibold';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      @media (max-width: 768px) {
        text-align: center;
       
      }

    }
  }
}