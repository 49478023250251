.mc-page-alliances {
  padding-bottom: 0px;

  .mc-alliances-container {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 992px) {
    // background-color: #faf8f2 !important;
    & > .mc-page-bg {
      margin-bottom: 35px;
      -webkit-border-bottom-right-radius: 35px;
      -webkit-border-bottom-left-radius: 35px;
      -moz-border-radius-bottomright: 35px;
      -moz-border-radius-bottomleft: 35px;
      border-bottom-right-radius: 35px;
      border-bottom-left-radius: 35px;
      overflow: hidden;

      .mc-app-rely {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .mc-section {
        position: relative;
        z-index: 1;
        width: 1100px;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0 18px;
        max-width: 90%;
        margin: auto;
        z-index: 2;
        left: 0px;
        right: 0px;
        top: -22px;
        bottom: 0px;

        h2 {
          text-align: left;
          margin: 10px 14px;
          font-size: 48px;
          font-style: normal;
          font-weight: 900;
          line-height: 50px;
          margin-top: 20px;
          width: 540px;
          color: #0F0F0F;
        }
        .mc-page-awards__available {
          text-align: left;
          margin: 10px 0px;
          font-size: 24px;
          font-style: normal;
          font-weight: 350;
          line-height: 28px;
          width: 470px;
          color: #0F0F0F;
        }
        & > .mc-app-container {
          max-width: 100%;
          padding: 0px;
        }

      }

      img {
        width: 100%;
        height: auto;
        margin-bottom: -10px;
      }
    }
  }

  .mc-custom-modal__center {
    @media only screen and (min-width: 992px) {
      // width: 650px !important;
    }
  }

  .mc-custom-modal__center--window {
    position: relative;

    .mc-custom-modal__close {
      transform: scale(0.75);
      right: 8px;
      top: 8px;
    }
    .mc-custom-modal__center--window__imager {
      position: absolute;
      left: 0px;
      top: 6px;
      height: 106px;
      width: 106px;
      z-index: 1;

      @media only screen and (min-width: 992px) {
        left: -295px;
        right: 0px;
        margin: auto;
        top: 0px;
      }
    }
    .mc-custom-modal__center--window__title {
      text-align: left;
      padding-left: 100px;
      color: var(--Color-Black-1, #0F0F0F);
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 28px;
      margin-top: 18px;
      padding-right: 24px;
      box-sizing: border-box;
      width: auto;
      margin-bottom: 12px;

      @media only screen and (min-width: 992px) {
        width: 390px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
        position: relative;
        left: 3px;
      }

      strong {
        color: var(--Color-Black-1, #0F0F0F) !important;
        font-weight: 900;        
        font-family: 'Myriad Pro Black';
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 28px;
      }
      span {
        display: block;
        color: var(--Color-Black-1, #0F0F0F);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }

    .mc-custom-modal__center--window__scroll {
      max-height: 160px;
      padding: 12px 7px 0px 7px;
      overflow: auto;
      overflow-y: scroll;
      background: #FFF;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

      @media only screen and (min-width: 992px) {
        max-height: 286px;
      }
    }
  }
}