.mc-slider-davipuntos-awards {
    background: url('../../../public/assets/davipuntos-logo/Modo_de_aislamiento-2.png');
    background-repeat: no-repeat;
    background-position: center right;
    position: relative;

    .slick-track {
        width: fit-content !important;
        display: flex !important;
        transform: translate3d(66px, 0px, 0px);
        -webkit-transform: translate3d(66px, 0px, 0px);
        -moz-transform: translate3d(66px, 0px, 0px);
        -ms-transform: translate3d(66px, 0px, 0px);
        -o-transform: translate3d(66px, 0px, 0px);
    }

    .mc-slider-rotator .slick-slider .slick-slide {
   
    }

    .davipuntos-awards-container {
        width: 1280px;
        max-width: 100%;
    }

    .mc-slider-rotator .slick-slider {
        width: 1130px;
        margin: 0 auto;
    }

    @media (min-width: 992px) {
        .mc-slider-rotator .slick-slider .slick-dots {
            width: fit-content;
            left: 56px;
        }

        .mc-slider-rotator .slick-slider .slick-arrow.slick-prev {
            left: 20px;
            right: auto;
        }

        .mc-slider-rotator .slick-slider .slick-arrow.slick-next {
            right: auto;
            left: 156px;
        }
        .mc-slider-rotator .slick-slider{
            padding: 0;
        }
    }
    .btn-davipuntos-awards{
        position: absolute;
        bottom: 45px;
        right: 0;
        padding-left: 14px;
        padding-right: 14px;
        @media (max-width: 992px) {
            bottom: 22px;
            left: 0;
            right: 0;
            margin: auto;
            width: 80% !important;
        }
    }
}

.mc-slider-rotator {
    @media (max-width: 992px) {
        padding-bottom: 130px;
    }
}