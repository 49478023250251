.mc-splash {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999999;
  background-color: #e3e3e3;
  background-repeat: no-repeat;
  background-size: 100%;

  &__logo {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 110px;
    height: auto;
    z-index: 2;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__container {
    position: relative;
    width: 800px;
    margin: 0 auto;
    height: 100%;

    @media (max-width: 992px) {
      margin: 0px !important;
      width: 100% !important;
      box-sizing: border-box;
      padding: 20px;
      padding-top: 120px;
    }

    &--left {
      position: relative;
      width: 50%;
      height: 100%;
      float: left;

      @media (max-width: 992px) {
        height: auto;
        width: 100%;
        text-align: center;
      }

      .mc-button-container {
        width: 224px;
        margin-top: 20px;

        @media (max-width: 992px) {
          width: 190px;
          margin: 30px auto;
        }
      }
    }
    &--right {
      position: relative;
      width: 50%;
      height: 100%;
      float: left;
      box-sizing: border-box;
      padding-left: 50px;

      @media (max-width: 992px) {
        height: auto;
        width: 100%;
        padding: 0px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
    &--title {
      color: var(--Contenidos, #131112);
      font-family: "Myriad Pro";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      margin-bottom: 10px;
    }
    &--text {
      color: var(--Contenidos, #131112);
      font-family: "Myriad Pro";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 16px;

      strong {
        font-weight: 700;
      }
    }
  }
}