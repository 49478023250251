.BannerInfo{
    box-sizing: border-box;
    margin: 70px 0;
    width: 100%;
    max-width: 700px;
    background: #C90C14;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 20px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    @media (max-width: 768px) {
        text-align: center;
        flex-direction: column;
        gap: 16px;
        padding: 10px;
     
    }
    .img_info{
        img{
            width: 106px;
        }
    }
    .content{
        color: #FFFFFF;
        h3{
            font-family: 'Myriad Pro Semibold';
            font-size: 24px;
            margin: 0;
        }
        p{
            margin: 0;
            margin-bottom: 10px;
            font-weight: bold;
        }
    }
}