.mc-app-container {
  position: relative;
  display: block;
  width: 1100px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 18px;

  @media only screen and (min-width: 992px) {
    max-width: 90%;
    margin: 0 auto;
    z-index: 2;
  }

  &.width-disable {
    width: 100%;
    max-width: 100%;
    padding: 0px;
  }
}