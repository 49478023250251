.mc-page-major-award {
  background-color: transparent;

  .mc-page-content {
    padding-top: 0;


    .slider-bg {
      height: 100%;
      background-size: cover;
      background-position: center;
      padding: 20px;
    }

    .section-slider-mayor-award {
      height: 500px;
      margin-top: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 992px) {
        flex-direction: column;
        height: 550px;
      }

      .slider-content {
        flex: 1 0 50%;
        margin-left: 72px;
        transform: translateY(0);

        @media (max-width: 992px) {
          display: flex;
          flex-direction: column;
          justify-content: end;
          padding-bottom: 30px;
        }

        p,
        h3,
        h5 {
          margin: 0;
          width: 100%;
          max-width: 400px;
        }

        p {
          text-align: left;
          color: var(--Secundarios-Neutro-9, #FFF);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-bottom: 16px;
        }

        h3 {
          color: var(--Secundarios-Neutro-9, #FFF);
          font-family: 'Myriad Pro Semibold';
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
          margin-bottom: 32px;
        }

        h5 {
          color: var(--Secundarios-Neutro-9, #FFF);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
        &.animated{
          transform: translateY(0%);
          animation: translate .5s linear;
          -webkit-animation: translate .5s linear;
          -webkit-transform: translateY(0%);
          -moz-transform: translateY(0%);
          -ms-transform: translateY(0%);
          -o-transform: translateY(0%);
          @keyframes translate
{
            0% {
              transform: translateY(100%);
            }
            100% {
              transform: translateY(0%);
            }
            
          }

        }
}

      .slider-cards {
        width: 150px;
        flex: 1 0 35%;

        @media (max-width: 992px) {
          display: none;
        }

        .slider-image {
          max-width: 150px;
          overflow: hidden;
          border-radius: 12px;
          box-shadow: 2px 1px 10px 0px rgba(0,0,0,0.64);
          -webkit-box-shadow: 2px 1px 10px 0px rgba(0,0,0,0.64);
          -moz-box-shadow: 2px 1px 10px 0px rgba(0,0,0,0.64);
          img {
            width: 100%;
            transform: scale(1.1);
          }
        }
      }
    }

    .section-ImageSlider {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: normal;
        height: auto;
      }

      .content-imageSlider {
        flex: 1 0 40%;

        div {
          width: 100%;
          max-width: 400px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          @media (max-width: 992px) {
            max-width: 100%;
            text-align: center;

          }

          h3 {
            color: var(--Contenidos, #131112);
            font-family: 'Myriad Pro Semibold';
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
            margin: 0;
            /* 112.5% */
          }

          p {
            text-align: left;
            color: var(--Contenidos, #131112);
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;

            @media (max-width: 992px) {
              text-align: center;

            }
          }
        }
      }

      .ImageSlider {
        flex: 1 0 60%;
      }
    }

    .section-tab-mayor-award {
      padding: 32px 0;

      .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--Primario-Primario-oscuro, #C90C14);

        .tab-item {
          padding: 12px 61px;
          cursor: pointer;

          @media (max-width: 768px) {
            padding: 12px 15px;

          }

          &.active {
            border-bottom: 5px solid var(--Primario-Primario-oscuro, #C90C14);

            h3 {
              font-family: 'Myriad Pro Black';
            }
          }

          h3 {
            color: var(--Secundarios-Neutro-4, #4D4D4D);
            font-size: 32px;
            font-style: normal;
            font-weight: normal;
            line-height: 32px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            @media (max-width: 768px) {
              font-size: 20px;
              line-height: normal;
             
            }
            img {
              width: 28px;
              position: relative;
              top: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .type-lini-alianzas {
    display: none !important;
  }
}
@media only screen and (max-width: 992px) {
  .type-lini-alianzas {
    .mc-icon {
      transform: scale(1.2) !important;
      opacity: 0.7;
    }
  }
}
.no-styles {
  color: #131112 !important;
}


.mc-header-container--major-award {
  .mc-header-container__nav {
    border-radius: 100px;
    background: rgba(18, 18, 18, 0.60);

    @media only screen and (min-width: 992px) {
      height: 48px;
      padding: 8px 0;
      margin-top: 2px;
      border: 0px;
      right: 90px;
      padding-right: 20px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    @media only screen and (min-width: 1350px) {
      right: 50px;
    }
    @media only screen and (min-width: 1450px) {
      right: 0px;
    }

    ul {
      li {
        a {
          color: #FFFFFF;

          .icon-mechanic,
          .icon-awards {
            svg {
              path {
                fill: #fff;
              }
            }
          }
          .icon-progress {
            svg {
              path {
                stroke: #fff;
              }
            }
          }
          .icon-awardsNew {
            color: #fff;
            svg {
              stroke: transparent;
              path {
                stroke: #fff;
                fill: transparent;
              }
            }
          }
          .icon-alliances {
            svg {
              path {
                fill: #fff;
              }
            }
          }

          span {
            color: #FFFFFF;

          }
        }
        .icon-more {
          svg {
            stroke: #fff;

            path {
              stroke: #fff;
            }
          }
        }
      }
      .mc-header-container__nav--button {
        span {
          color: #fff;
        }
      }
    }
  }

  &::before {
    content: 'hello before';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    backdrop-filter: blur(2px);
  }
}


button.pause-button{
  cursor: pointer;
  bottom: 97px;
  left: 61%;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  @media (max-width: 992px) {
    left: 0%;
    bottom: 0px;
    
  }
}
.wrap-progress-pause{
  position: relative;
  width: 35%;
  bottom: 118px;
  right: 12px;
  margin: 0 0 0 auto;
  height: 2px;
  background-color: #FFFFFF;
  @media (max-width: 992px) {
    width: 100%;
    left: 0%;
    bottom: -22px;
    
  }

  .progress-bar{
    height: 100%;
  }

}