.mc-alliances-container {
  margin: 30px 0px;
  &__items {
    &--item {
      position: relative;
      margin-bottom: 20px;

      &__container {
        position: relative;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 16px 18px;
        padding-bottom: 8px;

        border-radius: 12px;
        border-bottom: 1px solid #F0F0F0;
        background: linear-gradient(95deg, #FFF -7.03%, #F0F0F0 48.95%);
      }

      &__image {
        display: inline-block;
        vertical-align: top;
        width: 25%;

        img {
          width: 100%;
          height: auto;
        }

        span {
          display: block;
          width: 100%;
          background-position: center center !important;
          background-repeat: no-repeat !important;
          background-size: 78% !important;

          img {
            width: 100%;
            height: auto;
            display: block;

            &.dummy {
              opacity: 0 !important;
            }
          }
        }
      }

      &__info {
        display: inline-block;
        vertical-align: top;
        width: 75%;
        box-sizing: border-box;
        padding-left: 10px;

        .mc-alliances-button {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
          color: #131112;
          margin: 8px 0px;
          display: block;
          border: 0px;
          background-color: transparent;
          padding: 0px;
          cursor: pointer;
        }
      }

      &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 5px;
      }

      &__desc {
        
        * {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        strong {
          font-weight: bold;
        }
      }

      &__container {
        &.terms {
          .mc-custom-modal__center {
            @media only screen and (min-width: 992px) {
              width: 90%;
              max-width: 554px;
            }

            .mc-custom-modal__center--window {
              max-height: 80%;
              padding: var(--32, 32px) var(--size-md, 16px);

              .mc-custom-modal__center--window__desc {
                text-align: left;
                max-height: 280px;
                overflow: auto;

                @media only screen and (min-width: 992px) {
                  max-height: 320px;
                }
              }
            }
          }
        }
      }

      a,
      .mc-alliances-button {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        color: $colorText;
        margin: 8px 0px;
        display: block;
        background-color: transparent;
        border: 0px;
        padding: 0px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }

      &.type-1 {

      }
      &.type-2 {
        margin-bottom: 15px;

        .mc-alliances-container__items--item__image {
          position: absolute;
          left: 10px;
          top: 8px;
          width: 92px;
          height: auto;
        }
        .mc-alliances-container__items--item__info {
          height: auto;
          position: relative;
          padding-left: 96px;
          width: 100%;
          margin-bottom: 0px;

          .mc-alliances-container__items--item__rule {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            text-align: center;
            padding: 4px 0px;
            position: absolute;
            top: -8px;
            bottom: auto;

            color: var(--Contenidos, #131112);
            /* Title/h2-Black */
            font-family: "Myriad Pro Black";
            font-size: 40px;
            font-style: normal;
            font-weight: 900;
            line-height: 50px;

            display: block;
            right: 0px;
            left: 110px;
            box-sizing: border-box;
            text-align: center;
            height: 60px;

            border-radius: var(--M, 16px);
            background: rgba(255,255,255,0.85);

            @media only screen and (min-width: 992px) {
              border-radius: var(--M, 16px);
              background: var(--Primario-Primario, #ED1C27);
              box-shadow: 0px 6px 14px 0px rgba(35, 31, 32, 0.30);
              color: #fff;
              font-family: "Myriad Pro Bold";
              left: 114px;
            }

            span {

              font-size: 20px;
              font-style: normal;
              font-weight: 900;
              line-height: 24px;
              display: inline-block;
              margin-left: -4px;
            }
          }
          .mc-alliances-container__items--item__title {
            position: relative;
            color: var(--Colors-Text-Paragraph, #1A1A1A);
            font-family: "Myriad Pro";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: var(--Fonts-Line-height-20, 20px); /* 83.333% */
            margin-bottom: 12px;
          }
          .discount-image {
            width: 70px;

            &.minimal {
              width: 100%;
            }
          }
          .new-custom-link {
            color: var(--Primario-Primario, #ED1C27);
            font-family: "Myriad Pro";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            display: none;
          }
        }
        .mc-alliances-container__items--item__desc {
          font-size: 16px;
          font-style: normal;
          font-weight: 350;
          line-height: 20px;
          margin-bottom: 12px;

          @media only screen and (max-width: 992px) {
            min-height: 104px;
          }

          strong {
            font-weight: bold;
          }

          @media only screen and (min-width: 992px) {
            height: 80px;
            display: block !important;
          }
        }

        .terms {
          a {
            color: var(--Secundarios-Neutro-1, #040303);
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 20px;
            text-decoration-line: underline;
          }
        }
      }
      &.type-3 {
        background-color: rgb(233, 233, 233);
        padding: 18px;
        padding-bottom: 12px;
        box-sizing: border-box;

        .mc-alliances-container__items--item__info {
          height: 75px;
          position: relative;
          width: 100%;
          background-color: rgb(196, 196, 196);
          border-radius: 8px;
          padding: 10px;
          box-sizing: border-box;
          padding-left: 85px;
          margin-bottom: 12px;

          .mc-alliances-container__items--item__title {
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: 22px;
            margin-top: 0px;
            margin-bottom: 2px;
          }

          .mc-alliances-container__items--item__rule {
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 28px;
          }

          .mc-alliances-container__items--item__image {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 75px;
            height: 75px;
            -webkit-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.3);
            -moz-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.3);
            box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.3);
          }
        }
      }
      &.type-4 {
        .mc-alliances-container__items--item__info {
          padding-left: 50px;
          width: 100%;

          .mc-alliances-container__items--item__labels {
            background-color: rgb(243, 243, 243);
            box-sizing: border-box;
            padding: 16px;
            padding-left: 50px;

            .mc-alliances-container__items--item__title {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
            }

            .mc-alliances-container__items--item__rule {
              font-size: 24px;
              font-style: normal;
              font-weight: 900;
              line-height: 28px;
              margin-bottom: 6px;
            }

            .mc-alliances-container__items--item__desc {
              * {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }

          .mc-alliances-container__items--item__image {
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            width: 86px;
            z-index: 1;
            height: 86px;

            span {
              background-size: 60% !important;
            }
          }
        }
      }
    }
  }

  .mc-alliances-container__actions {
    background-color: transparent;
    text-align: center;
    margin: 18px auto;
    max-width: 390px;
    border: 1px solid $primaryColorText;
    border-radius: 100px;

    a {
      color: $primaryColorText;
      text-decoration: none;
      display: block;
      padding: 10px 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  &.mc-alliances-column-1 {
    .mc-alliances-container__items {

      @media only screen and (min-width: 992px) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
    }
  }

  &.mc-alliances-column-2 {
    .mc-alliances-container__items {

      @media only screen and (min-width: 992px) {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 47%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
    }
  }

  &.mc-alliances-column-3 {
    .mc-alliances-container__items {

      @media only screen and (min-width: 992px) {
        margin-left: -1.5%;
        margin-right: -1.5%;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 30.3%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
    }
  }

  .mc-alliances-container__items--item__container {
    .mc-custom-modal {
      .mc-custom-modal__close {
        transform: scale(0.8);
      }
    }
  }
}

.btn-termsOnModal{
  background-color: blue;
  width: fit-content !important;
  padding: 0 var(--m, 16px) !important;
  margin: 0 auto;
}

.wrap-allies{
  padding: 88px 0;
  background-color: #FFFFFF;
  padding-bottom: 10px;

  h2 {
    margin-top: 0px;
    margin-bottom: 40px;

    color: var(--Contenidos, #131112);
    text-align: center;
    
    /* Title/h3-Semibold */
    font-family: "Myriad Pro";
    font-size: 32px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 36px !important; /* 112.5% */
  }
}

.wrap-AssistanceServices{
  background-color: #FFFFFF;
  
}

.services-assistants {
  padding-top: 1px;

  .mc-app-container {
    @media only screen and (min-width: 992px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  button {
    border-radius: var(--s, 8px);
    border: 1px solid var(--Davivienda-Neutro-040303, #040303);
    background: var(--Davivienda-Neutro-FFFFFF, #FFF);
    height: 40px;
    width: 200px;
    margin: 40px auto;
    display: block;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
.mc-assistants {
  display: block;
  width: 100%;
  margin-top: 22px;

  &--item {
    width: 31.2%;
    vertical-align: top;
    display: inline-block;
    margin: 0 10px;
    border-radius: 18px 18px 18.778px 18.778px;
    border: 1.174px solid #BBB;
    background: linear-gradient(2deg, #F0F0F0 -44.81%, #FDFDFD 28.7%, #FFF 107.09%);
    box-shadow: 0px 5.868px 11.736px 0px rgba(135, 135, 135, 0.10);
    margin-bottom: 30px;

    @media only screen and (max-width: 992px) {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }

    &__header {
      border-radius: 18px 18px 0px 0px;
      background: linear-gradient(3deg, var(--Secundarios-Neutro-8, #F0F0F0) 66.38%, var(--Secundarios-Neutro-7, #B8B6B7) 257.2%);
      padding: 12px;
      height: 50px;

      @media only screen and (max-width: 992px) {
        height: auto;
        min-height: 50px;
      }

      display: flex;
      justify-content: center; /* Centra el contenido horizontalmente */
      align-items: center;
      
      &--title {
        color: var(--Primario-Primario, #ED1C27);
        font-family: "Myriad Pro";
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        padding-left: 50px;
        padding-right: 16px;

        .mc-icon {
          position: absolute;
          left: 4px;
          top: 0px;
          width: 40px;
          height: 40px;
          bottom: 0px;
          margin: auto;
          transform: translate(-4px, -2px);

          span {
            width: 40px;
            height: 40px;
            display: block;
            position: relative;
            &.icon-asistant_4 {
              top: 4px;
            }
            &.icon-asistant_10 {
              top: 2px;
            }
            &.icon-asistant_13 {
              top: 2px;
            }
            &.icon-asistant_14 {
              top: 0px;
            }
            &.icon-asistant_15 {
              top: 4px;
            }
            &.icon-asistant_16 {
              top: 6px;
            }

            svg {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    &__body {
      padding: 16px;
      color: #000;
      font-family: "Myriad Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      height: 100px;
      border-bottom: 1px solid #E4E4E4;
    }
    &__footer {
      h3 {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.126px; /* 150.897% */
        text-align: center;
      }
      &--include {
        box-sizing: border-box;
        padding: 10px;
        height: 114px;
        text-align: center;

        @media only screen and (max-width: 992px) {
          height: auto;
        }

        img {
          width: 50%;
          display: inline-block;
          vertical-align: middle;
        }

        &.qty-1,
        &.qty-2 {
          img {
            @media only screen and (min-width: 992px) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}