.mc-goal-counter {
  display: inline-block;
  position: relative;
  background-color: #F0F0F0;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  margin: 22px auto;
  margin-top: 0px;
  width: 100%;
  max-width: 329px;  
  box-shadow: 0 4px 16px 0px #8080804D;  

  div {
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    color: #131112;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      color: #ED1C27;
      font-weight: bold;
      margin: 0 3px;
    }
    img {
      display: inline-block;      
      margin-right: 6px;
    }

    strong {
      font-weight: 800;
    }
  }

  .mc-preloader-container {
    p {
      margin: 0;

      img {
        width: 20px;
        height: auto;
        position: relative;
        top: 2px;
      }
    }

  }

}