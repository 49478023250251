.mc-progressBar-container {
  position: relative;
  height: 8px;
  background: transparent;
  margin: 15px 0;
  border-radius: 20px;
  background-color: #F0F0F0;
  margin-left: 47px;

  .mc-progressBar-progress {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 0;
    background: rgb(237,28,39);
    background: -moz-linear-gradient(90deg, rgba(237,28,39,1) 0%, rgba(255,180,29,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(237,28,39,1) 0%, rgba(255,180,29,1) 100%);
    background: linear-gradient(90deg, rgba(237,28,39,1) 0%, rgba(255,180,29,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ed1c27",endColorstr="#ffb41d",GradientType=1);
    margin: auto;
    z-index: 1;
    border-radius: 4px;
    box-shadow: 0px 0px var(--m, 16px) 0px #ED1C27;

    span {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $secondaryColor;
      border-radius: 4px;
      right: -4px;
      top: -2px;
      z-index: 1;
    }

    &__character {
      position: absolute;
      top: -22px;
      z-index: 2;
      width: 52px;
      height: 52px;
      left: 0px;

      img {
        width: 100%;
        height: auto;
        position: relative;
        left: -20px;
      }
    }
  }

  .mc-progressBar-percent {
    z-index: 2;
    position: absolute;
    text-align: center;
    color: #4D4D4D;
    text-align: center;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    top: -7px;
    left: -42px;
    font-family: 'Myriad Pro Bold';
  }

  .mc-progressBar-bubble {
    position: absolute;
    width: 38px;
    height: auto;
    bottom: 2px;
    z-index: 2;

    img {
      width: 100%;
      height: auto;
      position: relative;
      left: -19px;
    }
  }

  .mc-progressBar-title {
    position: absolute;
    left: 0px;
    top: -26px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.5;
  }

  .mc-progressBar-label {
    position: absolute;
    right: 0px;
    top: -25px;
    font-size: 13px;
  }

  &.has-title {
    margin-top: 25px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &.col-2 {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }
}