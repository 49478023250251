// None
$primaryColor: #ED1C27;
$primaryColorHover: #fb434c;
$primaryColorLight: #ff5d65;
$primaryColorDark: #ce0e17;
$primaryColorDisable: #818181;
$primaryColorBg: #F7EFE4;
$primaryColorText: #3A3A3A;

$secondaryColor: #EEC276;
$secondaryColorHover: #f7d7a0;
$secondaryColorLight: #F0DAAA;
$secondaryColorDark: #3a3e47;
$secondaryColorDisable: #818181;

$errorColor: #ee0000;
$errorColorLight: #fff1f1;

$colorText: #000;
$colorTextL: #0F0F0F;
$colorTextL2: #353535;
$colorBg: #F5EFE5;


$secondaryColorPreferente: #0D3474;
$secondaryColorLightPreferente: #0048DB;
$whitePreferente: #F2F2F2;

:export {
  primaryColor: $primaryColor;
  secondaryColor: $secondaryColor;
  secondaryColorLight: $secondaryColorLight;
  secondaryColorLightPreferente: $secondaryColorLightPreferente;
  colorTextL: $colorTextL;
  primaryColorPreferente: $whitePreferente;
}