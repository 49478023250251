.mc-cardbox-container {
  position: relative;
  background-color: #E6E6E6B2;
  padding: 0px;
  box-sizing: border-box;
  margin: 5px;

  &>img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  &>h2 {
    color: #040303 !important;
    text-align: center !important;
    font-family: "Myriad Pro" !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    margin: 20px 0px !important;
  }

  &>div {
    color: $colorTextL;
    text-align: center;
    height: 94px;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px !important;

    @media only screen and (min-width: 992px) {
      // height: 132px;
      padding-left: 16px;
      padding-right: 16px;
    }

  }

  &.complete {
    margin: 0px !important;
  }

  &--fullContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    height: 52.5% !important;
    padding: 0px !important;

    @media only screen and (min-width: 992px) {
      height: 90% !important;
    }

    .mc-app-centry {

      h2,
      div {
        text-align: left !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        max-width: 100% !important;

        @media only screen and (min-width: 992px) {
          width: 560px;
        }
      }

      h2 {
        @media only screen and (min-width: 992px) {
          margin-bottom: 20px !important;
        }
      }

      div {
        @media only screen and (min-width: 992px) {
          font-size: 32px;
          font-style: normal;
          font-weight: 350;
          line-height: 36px;

          strong {
            font-weight: bold;
          }
        }

        @media only screen and (max-width: 992px) {
          font-size: 20px;
          font-style: normal;
          font-weight: 350;
          line-height: 24px;

          strong {
            font-weight: bold;
          }
        }
      }
    }

    @media only screen and (max-width: 992px) {
      .mc-app-rely {
        height: auto !important;
        display: block !important;
        margin-top: 36%;
      }
    }

    @media only screen and (min-width: 992px) {
      .mc-app-rely {
        height: auto !important;
        display: block !important;
        margin-top: 17.5%;
      }
    }


  }

  &.login-slider {
    .desc-paraTodos {
      position: relative;

      strong {
        font-weight: bold;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #C90C14;
        width: 45%;
        height: 10px;

        @media (max-width: 992px) {
          bottom: 0;
          display: none;
        }
      }
    }
  }

  &.davipuntosAwards {
    max-width: 377px;
    height: 180px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: #FFF;

    .LogoCardBox {
      align-self: flex-start;
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;

      figure {
        display: block;
        flex: 1 0 10%;
        margin: 0;
        padding: 3px;

        img {
          width: 27px
        }
      }

      .text-logo {
        flex: 1 0 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0 0 8px;
        background: linear-gradient(271deg, #5ABEDF 0.77%, #021d21 100%);
        -webkit-border-radius: 0px 0 0 8px;
        -moz-border-radius: 0px 0 0 8px;
        -ms-border-radius: 0px 0 0 8px;
        -o-border-radius: 0px 0 0 8px;
        h3
{
          writing-mode: sideways-lr;
          margin: 0;
          -webkit-writing-mode: sideways-lr;
          -ms-writing-mode: sideways-lr;
          color: var(--Secundarios-Neutro-8, #F0F0F0);
          text-align: center;
          font-family: "Myriad Pro";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
          /* 140% */
          letter-spacing: 0.8px;
        }
      }
    }

    .content-cardBox {
      padding: 8px 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;

      .img-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-bottom: 8px;
        min-height: 80px;

        img {
          width: 80px;
          object-fit: cover;
        }

        .title-copy {
          text-align: left;

          h4.title {
            color: var(--Secundarios-Neutro-3, #231F20);
            font-family: 'Myriad Pro Black';
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-align: left;
            margin: 0;
            max-width: 207px;
          }


        }

      }

      h5.subtitle {
        color: var(--Secundarios-Neutro-3, #231F20);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin: 0;
      }

      .double-points,
      .normal-points {
        p {
          margin: 0;
        }
      }

      .double-points {
        p {
          color: var(--Primario-Primario, #ED1C27);
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .normal-points {
        p {
          color: var(--Secundarios-Neutro-4, #4D4D4D);
          text-align: center;
          font-family: "Myriad Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            background-color: #4D4D4D;
            width: 100%;
            height: 0.5px;
          }
        }
      }
    }
  }
}