.mc-page-awards {
  padding-bottom: 0px;

  .mc-awards-container {
    margin-bottom: 0px;

    .mc-awards-container__items {
      padding-bottom: 5 0px;
    }
  }

  &__available {
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    color: #1A1A1A;
    text-align: center;
    padding: 0 20px;

    strong {
      font-weight: 900;
    }

    @media only screen and (min-width: 992px) {
      margin-bottom: -15px;
    }
  }
}

.sectionAwards{
  .mc-awards-container{
    margin-top: -230px;
    @media (max-width: 768px) {
      margin-top: 0;
     
    }
  }
}