@font-face {
  font-family: 'Myriad Pro Bold';
  src: url('../../fonts/MyriadPro-Bold.eot');
  src: url('../../fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-Bold.woff2') format('woff2'),
      url('../../fonts/MyriadPro-Bold.woff') format('woff'),
      url('../../fonts/MyriadPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Black';
  src: url('../../fonts/MyriadPro-Black.eot');
  src: url('../../fonts/MyriadPro-Black.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-Black.woff2') format('woff2'),
      url('../../fonts/MyriadPro-Black.woff') format('woff'),
      url('../../fonts/MyriadPro-Black.ttf') format('truetype');
  font-weight: black;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro BoldCond';
  src: url('../../fonts/MyriadPro-BoldCond.eot');
  src: url('../../fonts/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-BoldCond.woff2') format('woff2'),
      url('../../fonts/MyriadPro-BoldCond.woff') format('woff'),
      url('../../fonts/MyriadPro-BoldCond.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Cond';
  src: url('../../fonts/MyriadPro-Cond.eot');
  src: url('../../fonts/MyriadPro-Cond.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-Cond.woff2') format('woff2'),
      url('../../fonts/MyriadPro-Cond.woff') format('woff'),
      url('../../fonts/MyriadPro-Cond.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Light';
  src: url('../../fonts/MyriadPro-Light.eot');
  src: url('../../fonts/MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-Light.woff2') format('woff2'),
      url('../../fonts/MyriadPro-Light.woff') format('woff'),
      url('../../fonts/MyriadPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro BoldSemiCnIt';
  src: url('../../fonts/MyriadPro-BoldSemiCnIt.eot');
  src: url('../../fonts/MyriadPro-BoldSemiCnIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-BoldSemiCnIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-BoldSemiCnIt.woff') format('woff'),
      url('../../fonts/MyriadPro-BoldSemiCnIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro BoldSemiCn';
  src: url('../../fonts/MyriadPro-BoldSemiCn.eot');
  src: url('../../fonts/MyriadPro-BoldSemiCn.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-BoldSemiCn.woff2') format('woff2'),
      url('../../fonts/MyriadPro-BoldSemiCn.woff') format('woff'),
      url('../../fonts/MyriadPro-BoldSemiCn.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Myriad Pro CondIt';
  src: url('../../fonts/MyriadPro-CondIt.eot');
  src: url('../../fonts/MyriadPro-CondIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-CondIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-CondIt.woff') format('woff'),
      url('../../fonts/MyriadPro-CondIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro BoldSemiExt';
  src: url('../../fonts/MyriadPro-BoldSemiExt.eot');
  src: url('../../fonts/MyriadPro-BoldSemiExt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-BoldSemiExt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-BoldSemiExt.woff') format('woff'),
      url('../../fonts/MyriadPro-BoldSemiExt.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro LightCondIt';
  src: url('../../fonts/MyriadPro-LightCondIt.eot');
  src: url('../../fonts/MyriadPro-LightCondIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-LightCondIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-LightCondIt.woff') format('woff'),
      url('../../fonts/MyriadPro-LightCondIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro LightIt';
  src: url('../../fonts/MyriadPro-LightIt.eot');
  src: url('../../fonts/MyriadPro-LightIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-LightIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-LightIt.woff') format('woff'),
      url('../../fonts/MyriadPro-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Myriad Pro';
  src: url('../../fonts/MyriadPro-Regular.eot');
  src: url('../../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-Regular.woff2') format('woff2'),
      url('../../fonts/MyriadPro-Regular.woff') format('woff'),
      url('../../fonts/MyriadPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Semibold';
  src: url('../../fonts/MyriadPro-Semibold.eot');
  src: url('../../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-Semibold.woff2') format('woff2'),
      url('../../fonts/MyriadPro-Semibold.woff') format('woff'),
      url('../../fonts/MyriadPro-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiboldSemiCnIt';
  src: url('../../fonts/MyriadPro-SemiboldSemiCnIt.eot');
  src: url('../../fonts/MyriadPro-SemiboldSemiCnIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiboldSemiCnIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiboldSemiCnIt.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiboldSemiCnIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiboldCondIt';
  src: url('../../fonts/MyriadPro-SemiboldCondIt.eot');
  src: url('../../fonts/MyriadPro-SemiboldCondIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiboldCondIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiboldCondIt.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiboldCondIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiboldIt';
  src: url('../../fonts/MyriadPro-SemiboldIt.eot');
  src: url('../../fonts/MyriadPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiboldIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiboldIt.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiboldSemiCn';
  src: url('../../fonts/MyriadPro-SemiboldSemiCn.eot');
  src: url('../../fonts/MyriadPro-SemiboldSemiCn.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiboldSemiCn.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiboldSemiCn.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiboldSemiCn.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiboldCond';
  src: url('../../fonts/MyriadPro-SemiboldCond.eot');
  src: url('../../fonts/MyriadPro-SemiboldCond.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiboldCond.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiboldCond.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiboldCond.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiboldSemiExt';
  src: url('../../fonts/MyriadPro-SemiboldSemiExt.eot');
  src: url('../../fonts/MyriadPro-SemiboldSemiExt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiboldSemiExt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiboldSemiExt.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiboldSemiExt.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiCn';
  src: url('../../fonts/MyriadPro-SemiCn.eot');
  src: url('../../fonts/MyriadPro-SemiCn.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiCn.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiCn.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiCn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiExtIt';
  src: url('../../fonts/MyriadPro-SemiExtIt.eot');
  src: url('../../fonts/MyriadPro-SemiExtIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiExtIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiExtIt.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiExtIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiCnIt';
  src: url('../../fonts/MyriadPro-SemiCnIt.eot');
  src: url('../../fonts/MyriadPro-SemiCnIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiCnIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiCnIt.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiCnIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiboldSemiExtIt';
  src: url('../../fonts/MyriadPro-SemiboldSemiExtIt.eot');
  src: url('../../fonts/MyriadPro-SemiboldSemiExtIt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiboldSemiExtIt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiboldSemiExtIt.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiboldSemiExtIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiExt';
  src: url('../../fonts/MyriadPro-SemiExt.eot');
  src: url('../../fonts/MyriadPro-SemiExt.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/MyriadPro-SemiExt.woff2') format('woff2'),
      url('../../fonts/MyriadPro-SemiExt.woff') format('woff'),
      url('../../fonts/MyriadPro-SemiExt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}