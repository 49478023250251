.SectionAssistanceServices {
    padding: 0px 0 88px 0;
    h2{
        margin: 0;
    }
    p {
        color: var(--Secundarios-Neutro-3, #231F20);
        text-align: center;
        font-family: "Myriad Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 100% */
        margin-top: 0px;
    }

    .wrap-cards-assistance {
        padding: 24px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        @media (max-width: 768px) {
         flex-direction: column;
        }

        .card-assistance {
            width: 100%;
            max-width: 340px;

            .img_assistance {
                margin: 0;

                img {
                    width: 100%;
                    max-width: 340px;

                }
            }

            .content-assistance {
                min-height: 140px;
                padding: 16px 12px;
                text-align: center;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(230, 230, 230, 0.70) 100%);

                h3 {
                    color: var(--Secundarios-Neutro-1, #040303);
                    text-align: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    margin: 0;
                    margin-bottom: 16px;
                }

                p {
                    color: var(--Contenidos, #131112);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    margin: 0;
                }
            }
        }
    }


    .btn-allies-assistance {
        display: flex;
        justify-content: center;

        button {
            padding-left: 12px;
            padding-right: 12px;
            font-weight: normal;
        }

        .mc-button-container {
            width: fit-content;

            @media (max-width: 992px) {
                width: 100%;

            }
        }
    }
}