.mc-quiz {
  position: relative;
  padding: 40px 0px;
  padding-bottom: 80px;
  background-color: #fff;
  height: 300px;

  .container-quiz{
    @media only screen and (max-width: 576px){
      padding: 0;
    }

  }

  @media only screen and (max-width: 992px) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }


  .mc-quiz__labels {
    width: 340px;
    height: 100%;

    @media only screen and (max-width: 992px) {
      width: 100%;
      .mc-page-content__separator {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
    
    h3 {
      color: var(--Contenidos, #131112);
      font-family: "Myriad Pro";
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-top: 50px;

      @media only screen and (max-width: 992px) {
        text-align: center;
      }
    }
    p {
      color: var(--Contenidos, #131112);
      font-family: "Myriad Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      @media only screen and (max-width: 992px) {
        text-align: center;
      }

      strong {
        font-weight: 600;
      }
    }

    .mc-page-content__separator {
      margin-left: 0px;
      margin-right: auto;
    }
  }
  .mc-quiz__questions {
    position: absolute;
    right: 0px;
    left: 400px;
    top: 0px;
    bottom: 0px;

    @media only screen and (max-width: 992px) {
      position: relative;
      left: auto;
      height: 618px;
    }

    &--progress {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      height: 2px;
      border-radius: 0px 100px 100px 0px;
      background: var(--Secundarios-Neutro-6, #939393);

      &__state {
        position: absolute;
        left: 0px;
        height: 4px;
        border-radius: 100px;
        background: var(--Primario-Primario, #ED1C27);
        top: -1px;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
      }
    }
    &--items {
      position: absolute;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 2;
      top: 30px;

      &__item {
        display: none;
        height: 100%;

        &--title {
          color: var(--Contenidos, #131112);
          text-align: center;
          font-family: "Myriad Pro";
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }
        &--options {
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          height: 226px;
          text-align: center;

          @media only screen and (max-width: 992px) {
            height: 490px;
          }

          .mc-quiz__questions--items__item--option {
            position: relative;
            height: 100%;
            width: 25%;
            display: inline-block;
            vertical-align: top;
            margin: 0px;
            top: 0px;
            box-sizing: border-box;
            padding: 8px;

            @media only screen and (max-width: 992px) {
              width: 50%;
              height: 226px;
            }

            .single-option {
              height: 100%;
              box-sizing: border-box;
              border-radius: 8px;
              border: 1px solid transparent;
              background: var(--Secundarios-Neutro-9, #FFF);
              box-shadow: 0px 149px 42px 0px rgba(0, 0, 0, 0.00), 0px 65px 38px 0px rgba(0, 0, 0, 0.01), 0px 54px 32px 0px rgba(0, 0, 0, 0.05), 0px 24px 24px 0px rgba(0, 0, 0, 0.09), 0px 6px 13px 0px rgba(0, 0, 0, 0.08);
              padding: 8px;
              cursor: pointer;
              -webkit-transition: all 300ms ease;
              -moz-transition: all 300ms ease;
              -o-transition: all 300ms ease;
              transition: all 300ms ease;
              position: relative;
              top: 8px;

              &__image {
                margin-bottom: 5px;
                border: 2px solid #ED1C27;
                position: relative;

                &:before {
                  content: '';
                  position: absolute;
                  top: -2px;
                  right: -2px;
                  width: 0;
                  height: 0;
                  border-top: 32px solid #fff;
                  border-left: 32px solid transparent;
                  z-index: 2;
                }
                &:after {
                  content: '';
                  position: absolute;
                  bottom: -2px;
                  left: -2px;
                  width: 0;
                  height: 0;
                  border-bottom: 32px solid #fff;
                  border-right: 32px solid transparent;
                  z-index: 2;
                }
                
                img {
                  width: 100%;
                  height: auto;
                  margin-bottom: -6px;
                }
              }
              &__label {
                text-align: center;
                color: var(--Secundarios-Neutro-3, #231F20);
                text-align: center;
                font-family: "Myriad Pro";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              &:hover {
                border: 1px solid #ED1C27;
                position: relative;
                top: -8px;
              }
              &:active {
                top: -2px;
                opacity: 0.7;
              }
            }
          }
        }

        &.active {
          display: block;
        }
      }
    }
  }
  .mc-quiz__empty {
    position: absolute;
    right: 0px;
    left: 400px;
    top: 0px;
    bottom: 0px;

    @media only screen and (max-width: 992px) {
      position: relative;
      left: auto;
      height: 300px;
    }

    .center-content {
      text-align: center;
      filter: invert(100%);
      -webkit-filter: invert(100%);
      -moz-filter: invert(100%);
    }

    &--bg {
      background-image: url('../../../public/assets/images/empty-image.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;

      @media only screen and (max-width: 992px) {
        background-image: url('../../../public/assets/images/empty-mobile.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .mc-button-container {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: center;

        .mc-icon span img{
          width: 16px;
        }
      }
    }
  }

  &.active-1 {
    .mc-quiz__questions--progress__state {
      width: 25%;
    }
  }
  &.active-2 {
    .mc-quiz__questions--progress__state {
      width: 50%;
    }
  }
  &.active-3 {
    .mc-quiz__questions--progress__state {
      width: 75%;
    }
  }
  &.active-4 {
    .mc-quiz__questions--progress__state {
      width: 100%;
    }
  }
}