.SectionCards {
  &.visible{
    // background-color: lightcoral;
    // height: 80vh;
}
  position: sticky;
  top: 50px;
  background: var(--Secundarios-Neutro-8, #F0F0F0);
  box-sizing: border-box;
  // margin: 30px 0;
  overflow: hidden;
  background-image: url('../../../public/assets/images/plane.png');
  background-repeat: no-repeat;
  background-position: left 70%;
  background-size: 45%;
  @media (max-width: 992px) {
    background-image: none;    
  }

  .container-SectionCards{
    width: 1280px;
  }

  .SectionContainer {
    display: flex;

    @media (max-width: 992px) {
      display: block;
    }

    .sticky-title {
      position: relative;
      flex-basis: 46%;
      position: relative;
      top: 30px;

      align-self: flex-start;
      color: white;
      padding: 30px;

      @media (max-width: 992px) {
        flex-basis: 100%;
        position: static;
        text-align: center;

      }

      h4 {
        color: var(--Contenidos, #131112);
        font-family: 'Myriad Pro Semibold';
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        margin: 0;
        margin-bottom: 32px;

        @media (max-width: 992px) {
          font-size: 28px;
          line-height: normal;

        }
      }

      p.subtitle, .subtitle2 {
        margin: 0;
        color: var(--Contenidos, #131112);
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        text-align: left;

        @media (max-width: 992px) {
          font-size: 16px;
          line-height: normal;
          margin-bottom: 20px;

        }
        
      }
      p.subtitle{
        &.mb-4{
          margin-bottom: 32px;
        }
      }
      .mc-stair-slider__carousel--labels__tooltip {
        @media (max-width: 992px) {
          margin-top: 0px;

        }
      }
      .controls{
        @media (max-width: 992px) {
          display: none;
         
        }
        position: absolute;
        bottom: -150px;
        right: 20px;
        button{
          cursor: pointer;
          margin: 0;
          padding: 0;
          background: none;
          outline: none;
          border: none;
          display: inline-block;
          img{
            width: 32px;
          }
        }
      }
    }


    .scroll-content {
      flex-basis: 54%;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 0px 11px;
      height: 490px;
      overflow: hidden;
      overflow-y: scroll;
      scrollbar-width: none;
      background: linear-gradient(180deg, rgba(77, 77, 77, 0.00) 41.64%, rgba(77, 77, 77, 0.20) 73.37%, rgba(77, 77, 77, 0.40) 112.85%), #F0F0F0;

      @media (max-width: 992px) {
        background: linear-gradient(180deg, rgba(77, 77, 77, 0.00) 7.92%, rgba(77, 77, 77, 0.20) 66.03%, rgba(77, 77, 77, 0.40) 106.35%);
        padding: 30px;
        // height: 376px;
      }

      @media (max-width: 360px) {
        padding: 20px;
      }

      .card {
        position: relative;
        z-index: 1;
        flex: 0 0 48%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        opacity: 1;
        transition: transform 0.5s ease, opacity 0.5s ease;
        margin-bottom: 28px;

        @media (max-width: 992px) {
          flex: 1 0 100%;
          margin-bottom: 16px;

        }

        img {
          max-width: 100%;
          width: 100%;
        }

        &:nth-child(odd) {
          transform: translateY(32px);
          -webkit-transform: translateY(32px);
          -moz-transform: translateY(32px);
          -ms-transform: translateY(32px);
          -o-transform: translateY(32px);
          @media(max-width: 992px) {
              transform: translateY(0);
              -webkit-transform: translateY(0);
              -moz-transform: translateY(0);
              -ms-transform: translateY(0);
              -o-transform: translateY(0);
            }
        }

        &:nth-child(even) {
          transform: translateY(196px);
          -webkit-transform: translateY(196px);
          -moz-transform: translateY(196px);
          -ms-transform: translateY(196px);
          -o-transform: translateY(196px);
          @media(max-width: 992px) {
            transform: translateY(0);
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
          }
        }

        &:last-child{
          transform: translateY(196px);
          -webkit-transform: translateY(196px);
          -moz-transform: translateY(196px);
          -ms-transform: translateY(196px);
          -o-transform: translateY(196px);

          @media(max-width: 992px) {
            transform: translateY(0);
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
          }
        }
        
        &:nth-child(5) {
          transform: translateY(32px) translateX(-319px);
          -webkit-transform: translateY(32px) translateX(-319px);
          -moz-transform: translateY(32px) translateX(-319px);
          -ms-transform: translateY(32px) translateX(-319px);
          -o-transform: translateY(32px) translateX(-319px);

          @media(max-width: 992px) {
            transform: translateY(0);
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
          }
        }

        .content-body-card {
          position: absolute;
          z-index: 3;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          padding-bottom: 50px;

          @media (max-width: 992px) {
            padding-bottom: 90px;

          }

          .card-title {
            color: #FFF;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 0;

            @media (max-width: 768px) {
              font-size: 20px;

            }
          }

          .card-description {
            color: #FFF;
            text-align: center;
            font-family: "Myriad Pro";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin: 0;
            padding: 10px;

            @media (max-width: 768px) {
              font-size: 18px;

            }
          }
        }
      }

      .card.visible {
        opacity: 1;
      }
    }
  }



}

.mc-page.mechanic {
  .SectionContainer {
    .scroll-content .card {
      &:nth-child(5) {
        @media(min-width: 992px) {
          transform: translateY(30px) translateX(0px);
          -webkit-transform: translateY(30px) translateX(0px);
          -moz-transform: translateY(30px) translateX(0px);
          -ms-transform: translateY(30px) translateX(0px);
          -o-transform: translateY(30px) translateX(0px);
        }
      }
    }
  }
}