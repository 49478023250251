.mc-profile-card {
  position: relative;
  height: 32px;

  .mc-profile-avatar__letters {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 80px;
    background-color: transparent;
    color: $colorTextL;
    text-align: center;
    line-height: 26px;
    z-index: 2;
    border: 2px solid $colorTextL;
  }

  .mc-profile-name {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    padding-left: 0px;
    line-height: 30px;
    padding-left: 36px;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;

    .mc-profile-progress {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      strong {
        font-weight: 800;
        text-align: right;
        font-size: 16px;
        font-style: normal;
      }
      .mc-progressCircle-container {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: 0 7px;
        top: -2px;
        transform: rotate(-90deg);
        .mc-progressCircle-container__progress {
          width: 26px;
          height: 26px;
          
          .mc-app-rely {
            display: none !important;
          }
          .mc-progressCircle-container__progress--overlay {
            & > span {
              display: none !important;
            }
          }
        }
      }
      a {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        color: $colorTextL;
        margin-left: 2px;
        margin-right: 8px;
      }

    }
  }
}