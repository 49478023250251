.mc-page-faqs {
  padding-bottom: 0px;

  .mc-faqs-container {
    margin-top: -10px;
    margin-bottom: 70px;

    @media only screen and (min-width: 992px) {
      margin-top: 56px !important;
    }
  }
  
  @media only screen and (min-width: 992px) {
    & > .mc-page-bg {
      margin-bottom: 35px;
      -webkit-border-bottom-right-radius: 35px;
      -webkit-border-bottom-left-radius: 35px;
      -moz-border-radius-bottomright: 35px;
      -moz-border-radius-bottomleft: 35px;
      border-bottom-right-radius: 35px;
      border-bottom-left-radius: 35px;
      overflow: hidden;

      .mc-app-rely {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .mc-section {
        position: relative;
        z-index: 1;
        width: 1100px;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0 18px;
        max-width: 90%;
        margin: auto;
        z-index: 2;
        left: 0px;
        right: 0px;
        top: -30px;
        bottom: 0px;

        h2 {
          text-align: left;
          margin: 10px 14px;
          font-size: 48px;
          font-style: normal;
          font-weight: 900;
          line-height: 50px;
          margin-top: 20px;
          width: 540px;
          color: #0F0F0F;
        }
        .mc-page-awards__available {
          text-align: left;
          margin: 10px 0px;
          font-size: 24px;
          font-style: normal;
          font-weight: 350;
          line-height: 28px;
          width: 470px;
          color: #0F0F0F;
        }
        & > .mc-app-container {
          max-width: 100%;
          padding: 0px;
        }

      }

      img {
        width: 100%;
        height: auto;
        margin-bottom: -10px;
      }
    }
  }
}