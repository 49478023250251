.mc-page.not-found {
  padding-bottom: 0px;
  position: relative;

  .mc-banner-container {
    img {
      margin-bottom: -6px;
    }
  }

  .not-found--hero {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    max-width: 420px;
    margin: auto;

    h1 {
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 59.979px;
      margin: 0 auto;
      text-align: center;
      color: $primaryColorText;
      line-height: 42px;
    }
    h2 {
      font-size: 123px;
      font-style: normal;
      font-weight: 900;
      line-height: 110px;
      margin: 0 auto;
      text-align: center;
      color: $primaryColorText;
    }
    h3 {
      color: var(--Contenidos, #131112);
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      margin: 0px;
      padding: 0 10px;
    }
    img {
      width: 100%;
      height: auto;
    }
    .mc-button-container {
      margin-top: 35px;
    }

    @media only screen and (min-width: 992px) {
      max-width: 1100px;
      text-align: center;

      .not-found--hero__layout{
        display: inline-block;
        vertical-align: middle;
        width: 350px;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        width: 380px;
        margin-right: 20px;
      }
    }
  }
}